import React from 'react';

import { Box } from "../../ui/box/Box";
import { Title } from '../../ui/title/Title';
import { CreateAccount } from '../../components/createAccount/CreateAccount';
import { Back } from '../../components/back/Back';
import { Accordion } from '../../ui/accordion/Accordion';
import { Accounts } from '../../components/accounts/Accounts';


export const NewAccount=() => {

  return (
    <div className="new-account">
      <Back></Back>
      <Title title="Cuentas"></Title>
      <Box>
        <CreateAccount></CreateAccount>
      </Box>
      <Accordion
        title = {'Todas las cuentas'}
      >
        <Accounts></Accounts>
      </Accordion>
    </div>
  );
}
