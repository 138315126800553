import axios from "axios";

const axiosInstance = axios.create({
  baseURL: 'https://proyectox.titamedia.com:8844/catalogo-service/',
  headers: {
    'Content-Type': 'application/json',
    'accept': '*/*',
    'Authorization':'Basic YWRtaW46YWRtaW4='
  }
});

const axiosInstance2 = axios.create({
  baseURL: 'https://proyectox.titamedia.com:8846/',
  headers: {
    'Content-Type': 'application/json',
    'accept': '*/*',
    'Authorization':'Basic NzQ4OTFlM2EtOWM3MC00N2M2LWI1YjktOTQzMWMzYjM5ZTQyOmIwOTA4MTFjLWIzNmQtNGI4Mi1iZTM2LTMyNmEwNDk2MjQ2Mg=='
  }
});


export {
  axiosInstance,
  axiosInstance2
}
