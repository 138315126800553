import React, { useState, useEffect } from 'react'

export const TablePaginator=(props) => {

  const [pages, setPages] = useState([])
  const [pageSelected, setPageSelected] = useState(0)
  const [pageSlice, setPageSlice] = useState(0)

  /**
   * crea listado de paginas que son necesarias para pintar los datos
   * @returns lisgtado con las paginas
   */
  const populatePages = () => {
    let pagesNun = ( props.data?.length || 1 ) / ( props.itemsPerPage || 1 )
    let pagesList = []
    for (let i = 0; i < pagesNun; i++) {
      pagesList.push({
        label: i+1
      })
    }
    return pagesList
  }
  
  useEffect(() => {
    setPages(populatePages())
  }, [props]);

  const calculatePage = (max) => {
    let page = 0
    if ( props.pageSelected && props.pageSelected + max > pages.length) {
      page = pages.length - max
    }else if(props.pageSelected){
      page = props.pageSelected
    }
    return page
  }
  useEffect(() => {
    setPageSelected(calculatePage(0))
    setPageSlice(calculatePage(3))
  }, [pages]);

  return (
    <div className={`paginator`}>
      {
        pageSelected !== 0 &&
        <>
        <div className={`page page-tag page-action`} onClick={()=>{props.setPageSelected(pageSelected -1)}}>
          {'<'}
        </div>
        <div className={'page page-length'}>{pages[0].label} ...</div>
        </>
      }
      {
        pages.slice(pageSlice,pageSlice+3).map((page, index)=>{
          return (
            <div 
              className={`page page-action ${pageSelected === page.label-1 ? 'page-active': ''}`} 
              key={index} 
              onClick={()=>{props.setPageSelected(page.label-1)}}
            >
              {page.label}
            </div>
          )
        })
      }
      {
        pageSelected + 3 < pages.length &&
        <>
        <div className={'page page-length'}>... {pages[pages.length-1].label}</div>
        <div className={'page page-tag page-action'} onClick={()=>{props.setPageSelected(pageSelected+1)}}>
          {'>'}
        </div>
        </>
      }
    </div>
  );
}