import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";

import logo from '../../../images/logo-tita.svg';

import { shema } from './NavShema';
import { NavItem } from './NavItem';
import { Select } from '../../ui/select/Select';

import {storesList, storeActive, changeStore} from '../../../app/user/userSlice';

import {togleBodyFrozen} from '../../../utilities'

import {
  setPath,
  getPath,
} from '../../../app/nav/navSlice';


export const Nav=() => {
  const dispatch = useDispatch();
  const activeItem = useSelector(getPath);
  let history = useHistory();

  const [activeGroup, setActiveGroup] = useState('')
  const stores = useSelector(storesList);
  const activeStore = useSelector(storeActive);
  const [storeList, setStoreList] = useState([])
  const [searchNav, setSearchNav] = useState([])
  const [activeNav, setActiveNavNav] = useState()

  /**
   * evalúa el path y setea su valor como ítem activo
   */
  useEffect(() => {
    history.listen(location => {
      if(location.pathname.split('/')[1] !== activeItem){
        dispatch(setPath(location.pathname.split('/')[1]))
      }
    });
    for (let i = 0; i < shema.length; i++) {
      const group = shema[i];
      let setItem = group.items.find((item)=>{
        return item.path ===  history.location.pathname.split('/')[1]
      })
      
      if(setItem){
        setActiveGroup(group.name)
        dispatch(setPath(setItem.path))
        return
      }
      if(shema.length-1 === i){
        setActiveGroup()
      }
    }
  }, [activeItem]);

  /**
   * llena la lista de cuentas del usuario
   */
  useEffect(()=>{
    let list = stores.map((store)=>{
      return{
        ...store,
        label : store.store,
        value : store.store
      }
    })

    list.sort(function (a, b) {
      if (a.label > b.label) {
        return 1;
      }
      if (a.label < b.label) {
        return -1;
      }
      // a must be equal to b
      return 0;
    });
    setStoreList(list)
  },[stores])

  /**
   * cambio de pantalla
   * @param {String} path ruta de la pantalla a ir
   */
  const setActiveItem = (path)=>{
    dispatch(setPath(path))
  }

  /**
   * camvio de tienda
   * @param {*} value tienda seleccionada
   */
  const change = (value) =>{
    dispatch(changeStore(value))
  }

  /**
   * revisa si el menu actual (url) es un menu con permisos si no retorna al home 
   */
  useEffect(()=>{
    let ungroup = JSON.parse(JSON.stringify(shema)).reduce((a, b) => {
      a.items = a.items.concat(b.items)
      return a
    }).items
    let item = ungroup.find(it => it.path ===  history.location.pathname.split('/')[1])
    if( activeStore.rols && item && !activeStore.rols.find(permission => permission.id === item.backId)){
      history.push("/home");
    }
  },[activeStore])

  useEffect(()=>{
    const handleResize = () => {
      if (window.innerWidth > 900) togleBodyFrozen(false)
    }
    window.addEventListener('resize', handleResize)
    return () =>{ window.removeEventListener('resize', handleResize)}
  },[])

  return (
    <div className={`menu ${activeNav ? 'menu-active' : ''}`}>
      <div className="logo">
        <img src={logo} alt='logo'/>
        <i 
          className='icon icon-m icon-close-white icon-action' 
          onClick={()=>{setActiveNavNav(false); togleBodyFrozen(false)}}>
        </i>
        <i 
          className='icon icon-l icon-nav icon-action' 
          onClick={()=>{setActiveNavNav(true); togleBodyFrozen(true)}}>
        </i>
      </div>
      <nav>
        <div className="menu-select">
          <Select 
            options={storeList}
            placeholder="seleccione una opción"
            selected={change}
            selectValue={activeStore.store}
            filter
          ></Select>
        </div>
        <div className="menu-input">
          <i className="icon icon-s icon-search"></i>
          <input placeholder="Buscar" value={searchNav} onChange={e => setSearchNav(e.target.value)}/>
        </div>
        <ul>
          {
            shema.map((item, index)=>{
              if(
                activeStore.rols && activeStore.rols.find(permission => permission.seccion === item.section) &&
                ( new RegExp(searchNav, 'i').test(item.name) || 
                item.items.find(el =>  new RegExp(searchNav, 'i').test(el.name)) )
              ){
                return (
                  <NavItem
                    activeGroup={activeGroup === item.name || searchNav.length > 0}
                    activeLink={activeItem}
                    {...item}
                    key={index}
                    setGroup={setActiveGroup}
                    setItem={
                      (pathSelected) => {
                        setActiveItem(pathSelected); 
                        setActiveNavNav(false) 
                        togleBodyFrozen(false)
                        window.scroll({top: 0})
                      }
                    }
                    search={searchNav}
                  ></NavItem>
                )
              }
            })
          }
        </ul>
      </nav>
    </div>
  );
}