import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { InputFile } from '../../ui/inputFile/InputFile';

import { toast } from 'react-toastify';

import {
  loadAsync,
  listAsync
} from '../../../app/files/filesSlice';

export const LoadFile=(props) => {
  const dispatch = useDispatch();
  const [file, setFile] = useState('') 

  /**
   * validación de llenado de los campos necesarios del formulario
   * @returns booleano con el resultado
   */
  const disabled = () => {
    if(file) return false
    return true
  }

  /**
   * envio de formulario
   */
  const load = () => {
    dispatch(loadAsync({
      file:file,
      type: props.category
    })).then((response)=>{
      if(response.error){
        toast.error(response.error.message)
      }else{
        dispatch(listAsync ({directory:props.type, category:props.category}))
        setFile('')
        toast.success("Archivo cargado con éxito")
      }
    })
  }

  return (
    <form className="load-file">
      <div className="form-row">
        {
          props.formats &&
          <p>Ya cuentas con el formato para cargar el archivo? si no 
            {
              props.formats.map((format, index) =>{
                return (
                  <i key={index}> haz <a className="link" href={format.url}>click aquí</a> para descargar el formato
                    {format.type ? ` de ${format.type}` : ''}
                    {index < props.formats.length -1 ? ' o ': ''}
                  </i>
                )
              } )
            }
          </p>
        }
      </div>
      <div className="form-actions-row">
        <InputFile value={file} setValue={setFile}></InputFile>
        <button className="secondary-btn" disabled={disabled()} type='button' onClick={()=>{load()}}>Enviar</button>
      </div>
    </form>
  );
}
 