import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { downLoadFile, deleteFile, listFiles, loadFile, listFilesScheduled, deleteFileScheduled } from './filesAPI';


const initialState = {
  list:[],
  listScheduled:[],
  status: 'idle'
};

/**
 * 
 */
export const downLoadAsync = createAsyncThunk(
  'files/downLoad',
  async (data) => {
    const response = await downLoadFile(data.file);
    console.log(">>>>>>>>>>>>>> response",response);
    const url = window.URL
        .createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${data.file}`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    return 'OK'
  }
);

export const deleteAsync = createAsyncThunk(
  'files/delete',
  async (data) => {
    const response = await deleteFile(data.file);
    return response
  }
);

export const listAsync = createAsyncThunk(
  'files/list',
  async (data) => {
    const response = await listFiles(data.directory);

    let filesList =[]
    if(data.category){
      filesList = response.filter(file=> file.name.startsWith(data.category))
    }else{
      filesList = response
    }
    return filesList.map((file) => {
      return {
        ...file,
        label: file.name
      }
    })
  }
);

export const loadAsync = createAsyncThunk(
  'files/load',
  async (data) => {
    const response = await loadFile(data.file, data.type);
    return response
  }
);

export const listScheduledAsync = createAsyncThunk(
  'files/listScheduled',
  async (data) => {
    const response = await listFilesScheduled();

    let filesList =[]
    if(data.category){
      filesList = response.filter(file=> file.jobGroup === data.category)
    }else{
      filesList = response
    }
    return filesList.map((file) => {
      return {
        ...file,
        label: file.name
      }
    })
  }
);

export const deleteScheduledAsync = createAsyncThunk(
  'files/deleteScheduled',
  async (data) => {
    const response = await deleteFileScheduled(data.jobName, data.jobGroup);
    return response
  }
);

export const filesSlice = createSlice({
  name: 'acount',
  initialState,
  reducers: {
    setAcount: (state, action) => {
      state.selectAcount = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(downLoadAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(downLoadAsync.fulfilled, (state, action) =>  {
        state.status = 'idle';
      })
      .addCase(downLoadAsync.rejected, (state, action)=>{})
      .addCase(deleteAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteAsync.fulfilled, (state, action) =>  {
        state.status = 'idle';
      })
      .addCase(deleteAsync.rejected, (state, action)=>{})
      .addCase(listAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(listAsync.fulfilled, (state, action) =>  {
        state.status = 'idle';
        state.list = action.payload
      })
      .addCase(listAsync.rejected, (state, action)=>{})
      .addCase(loadAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(loadAsync.fulfilled, (state, action) =>  {
        state.status = 'idle';
      })
      .addCase(loadAsync.rejected, (state, action)=>{})
      .addCase(listScheduledAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(listScheduledAsync.fulfilled, (state, action) =>  {
        state.status = 'idle';
        state.listScheduled = action.payload
      })
      .addCase(listScheduledAsync.rejected, (state, action)=>{})
      .addCase(deleteScheduledAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteScheduledAsync.fulfilled, (state, action) =>  {
        state.status = 'idle';
      })
      .addCase(deleteScheduledAsync.rejected, (state, action)=>{})
  },
});

export const { setAcount } = filesSlice.actions;

export const list = (state) => state.files.list;

export const listScheduled = (state) => state.files.listScheduled;

export default filesSlice.reducer;
