import React from 'react';
import { useHistory } from "react-router-dom";


export const Back=() => {

  let history = useHistory();

  return (
    <div className="back" onClick={()=>{history.goBack()}}>
      <i className="icon icon-back"></i>
      Volver
    </div>
  );
}
 