import React, { useEffect, useRef } from 'react';


export const TextArea=(props) => {

  const textAreaFile = useRef();

  /**
   * expande la altura del campo dependiendo del tamaño de su contenido mediante la propiedad scrollHeight
   * @param {*} element input a evaluar
   */
  const autoGrow =(element)=>{
    element.style.height = 0+"px";
    element.style.height = element.scrollHeight-(element.scrollHeight <= 37 ? 10 :0)+"px";
  }

  useEffect(() => {
    autoGrow(textAreaFile.current)
  }, [props.value]);

  return (
    <textarea 
      onInput={(e)=>{autoGrow(e.target)}} 
      placeholder={props.placeholder}
      value={props.value}
      onChange={props.onChange}
      ref={textAreaFile}
    ></textarea>
  );
}