import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createProductSku, downLoadSKU, createSku, uploadImages, changeProductSku, scheduledSku, scheduledProductSku, deleteImages } from './productAndSkuAPI';


const initialState = {
  status: 'idle',
  selectedStoreDownload:{
    store:'',
    to:''
  },
};


/**
 * 
 */
export const createAsync = createAsyncThunk(
  'productAndSku/create',
  async (data) => {
    const response = await createProductSku(data.email, data.storeName, data.path);
    return  response
  }
);

export const downLoadAsync = createAsyncThunk(
  'productAndSku/dowload',
  async (data) => {
    const response = await downLoadSKU(data.email, data.storeName, data.to);
    return  response
  }
);

export const createSKUAsync = createAsyncThunk(
  'productAndSku/createSKU',
  async (data) => {
    const response = await createSku(data.email, data.storeName, data.path);
    return  response
  }
);

export const scheduleSKUdAsync = createAsyncThunk(
  'productAndSku/scheduledSKU',
  async (data) => {
    const response = await scheduledSku(data.email, data.storeName, data.path, data.update, data.date);
    return response
  }
);



export const uploadImagesAsync = createAsyncThunk(
  'productAndSku/uploadImg',
  async (data) => {
    const response = await uploadImages(data.email, data.storeName);
    const url = window.URL
        .createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `descarga.xlsx`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    return 'OK'
  }
);

export const changeAsync = createAsyncThunk(
  'productAndSku/change',
  async (data) => {
    const response = await changeProductSku(data.email, data.storeName, data.path, data.update);
    return  response
  }
);

export const scheduledProductSkuAsync = createAsyncThunk(
  'productAndSku/scheduledProductSku',
  async (data) => {
    const response = await scheduledProductSku(data.email, data.storeName, data.path, data.update, data.date);
    return response
  }
);

export const deleteImagesAsync = createAsyncThunk(
  'productAndSku/deleteImg',
  async (data) => {
    const response = await deleteImages(data.email, data.storeName, data.path);
    return response
  }
);


export const productAndSkuSlice = createSlice({
  name: 'acount',
  initialState,
  reducers: {
    setStore: (state, action) => {
      state.selectedStoreDownload = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createAsync.fulfilled, (state, action) =>  {
        state.status = 'idle';
      })
      .addCase(createAsync.rejected, (state, action)=>{})
      .addCase(downLoadAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(downLoadAsync.fulfilled, (state, action) =>  {
        state.status = 'idle';
      })
      .addCase(downLoadAsync.rejected, (state, action)=>{})
      .addCase(createSKUAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createSKUAsync.fulfilled, (state, action) =>  {
        state.status = 'idle';
      })
      .addCase(createSKUAsync.rejected, (state, action)=>{})
      .addCase(uploadImagesAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(uploadImagesAsync.fulfilled, (state, action) =>  {
        state.status = 'idle';
      })
      .addCase(uploadImagesAsync.rejected, (state, action)=>{})
      .addCase(changeAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(changeAsync.fulfilled, (state, action) =>  {
        state.status = 'idle';
      })
      .addCase(changeAsync.rejected, (state, action)=>{})
      .addCase(deleteImagesAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteImagesAsync.fulfilled, (state, action) =>  {
        state.status = 'idle';
      })
      .addCase(deleteImagesAsync.rejected, (state, action)=>{})
  },
});


export const selectDownload = (state) => state.productAndSku.selectedStoreDownload;

export default productAndSkuSlice.reducer;
