import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { storeActive } from '../../../app/user/userSlice';
import { inventoryListAsync,list, setInventorys } from '../../../app/inventory/inventorySlice';


import { Card } from '../../ui/card/Card'

export const Inventory = () => {

  const  dispatch = useDispatch();
  const store = useSelector(storeActive);

  const inventorys = useSelector(list)
  
  useEffect ( ()  => {
    downLoadInventorys();
    
  },[])

  /**
   * descarga de inventarios 
   * @returns 
   */
  const downLoadInventorys = () => {
    return new Promise((resolve, reject)=>{
      dispatch(inventoryListAsync({
        store: store.store
      })).then((response)=>{
        if(response.error){
          console.log(response.error.message)
        }else{
          resolve()
        }
      })
    })
  }



  return (
    <>
      <div className="inventory">
        {
          inventorys.map( inventory =>  (
            <Card 
              key={inventory.id }
              label={'ID: '}
              almacen={inventory.name}
              id={inventory.id}
              estado={inventory.isActive}
            />
          ))
        }
      </div>
    </>
  )
}
