import React from 'react';


export const BodyCell=(props) => {

  return (
    <div className="body-cell" style={{flex:`${props.cell.size} 0`}}>
      {
        !props.cell.template ?
        props.cell.value :
        props.cell.template.render(props.cell.row)
      }
    </div>
  );
}