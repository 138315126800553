import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { downLoadCategoryTree, updateCategoryTree, createCategoryTree, replicateCategoryTree, scheduledCategoryTree } from './categoryTreeAPI';


const initialState = {
  status: 'idle',
  selectDownload:{
    store:'',
    to:''
  },
  actionUI: 'new'
};


/**
 * 
 */
export const downLoadAsync = createAsyncThunk(
  'categoryTree/downLoad',
  async (data) => {
    const response = await downLoadCategoryTree(data.email, data.storeName, data.to);
    return response
  }
);

export const updateAsync = createAsyncThunk(
  'categoryTree/update',
  async (data) => {
    const response = await updateCategoryTree(data.email, data.storeName, data.path);
    return response
  }
);

export const createAsync = createAsyncThunk(
  'categoryTree/create',
  async (data) => {
    const response = await createCategoryTree(data.email, data.storeName, data.path, data.update);
    return response
  }
);

export const replicateAsync = createAsyncThunk(
  'categoryTree/replicate',
  async (data) => {
    const response = await replicateCategoryTree(data.email, data.origin, data.destiny, data.update);
    return response
  }
);

export const scheduledAsync = createAsyncThunk(
  'categoryTree/scheduled',
  async (data) => {
    const response = await scheduledCategoryTree(data.email, data.storeName, data.path, data.update, data.date);
    return response
  }
);

export const categoryTreeSlice = createSlice({
  name: 'acount',
  initialState,
  reducers: {
    setAcount: (state, action) => {
      state.selectAcount = action.payload;
      state.actionUI = 'edit';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(downLoadAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(downLoadAsync.fulfilled, (state, action) =>  {
        state.status = 'idle';
      })
      .addCase(downLoadAsync.rejected, (state, action)=>{})
      .addCase(updateAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateAsync.fulfilled, (state, action) =>  {
        state.status = 'idle';
      })
      .addCase(updateAsync.rejected, (state, action)=>{})
      .addCase(createAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createAsync.fulfilled, (state, action) =>  {
        state.status = 'idle';
      })
      .addCase(createAsync.rejected, (state, action)=>{})
      .addCase(replicateAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(replicateAsync.fulfilled, (state, action) =>  {
        state.status = 'idle';
      })
      .addCase(replicateAsync.rejected, (state, action)=>{})
      .addCase(scheduledAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(scheduledAsync.fulfilled, (state, action) =>  {
        state.status = 'idle';
      })
      .addCase(scheduledAsync.rejected, (state, action)=>{})
  },
});

export const { setAcount } = categoryTreeSlice.actions;

export const selectDownload = (state) => state.categoryTree.selectDownload;
export const actionUI = (state) => state.categoryTree.actionUI;

export default categoryTreeSlice.reducer;
