import { axiosInstance } from "../../serviceConf";

export function downLoadProductSpecifications( email, storeName ) {
  let url = `api/Category/productSpecificationDownload`
  let params = {
    email: email,
    origin: storeName
  }

  return new Promise((resolve, reject) =>
    axiosInstance.get(url,{ params , responseType: 'blob'},)
    .then((response) => {
      console.log(response.data)
      resolve(response.data)
    }).catch((err)=>{
      reject(err)
    })
  )
}

export function createProductSpecifications( email, storeName, sku, path ) {
  let url = `api/Category/createSpecificationFromFile`
  let params = {
    email: email,
    store: storeName,
    sku: sku,
    path: path
  }
  return new Promise((resolve, reject) =>
    axiosInstance.get(url,{ params })
    .then((response) => {
      resolve(response.data)
    }).catch((err)=>{
      reject(err)
    })
  )
}

export function dowloadProductAttr( email, storeName, to ) {
  let url = `api/Category/getAllProductSpecificationsFromAStore`
  let params = {
    email: email,
    origin: storeName,
    to: to
  }
  return new Promise((resolve, reject) =>
    axiosInstance.get(url,{ params })
    .then((response) => {
      resolve(response.data)
    }).catch((err)=>{
      reject(err)
    })
  )
}