import React, { useState } from 'react'
import { selectUser, storeActive } from '../../../app/user/userSlice';

import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { LoadInventory } from '../../components/loadInventory/LoadInventory'
import { Modal } from '../modal/Modal'
import { modalSizes } from '../../ui/modal/ModalShema';

import { inventoryExportAsync } from '../../../app/inventory/inventorySlice';

export const Dropdown = ({opt1, opt2, id}) => {

  const [state, setstate] = useState(false)

  const [stateModal, setStateModal] = useState(false)
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const storeName = useSelector(storeActive)


  const showDropDown = () =>{
      setstate(true)
  }

  const hideDropdown = () => {
      setstate(false)
  }

  //esto no deberia ir aqui 
  const exportInventory = () => {
    return new Promise((resolve, reject) => {
      dispatch(inventoryExportAsync({
        email:user.email, 
        store:storeName.store, 
        warehouse: id
      })).then((response)=>{
        if(response.error){
          toast.error(response.error.message)
        }else{
          toast.success("Inventario Exportado con éxito, pronto llegará el archivo a su correo electrónico ")
          resolve()
        }
      })
      })
  }

    return (
       <>
       
       <div className="dropdown" onClick={showDropDown}  onMouseLeave={hideDropdown}  >
           <div className="dropdown-icon-container">
                <i className="icon icon-menu-inventory"/>
           </div>        
                  {state ?
                        ( <ul className="dropdown-menu" onMouseEnter={showDropDown}>
                            <li className="dropdown-li" onClick={exportInventory}>{opt1}</li>
                            <li className="dropdown-li" onClick={()=>{setStateModal(true)}}>{opt2}</li>
                        </ul>) 
                                
                  :null}
        </div> 

        <Modal
            open={stateModal}
            size={modalSizes.STANDAR}
            setState={setStateModal}>

            <LoadInventory store={storeName.store} email={user.email} />
              
            
        </Modal>
    </>
  )
}
