import React from 'react';
import { useHistory } from "react-router-dom";
import { useSelector } from 'react-redux';

import { Accordion } from "../../ui/accordion/Accordion";
import { Box } from "../../ui/box/Box";

import { RecentAccounts } from '../../components/recentAccounts/RecentAccounts';
import { CreateAccount } from '../../components/createAccount/CreateAccount';

import { storeActive } from '../../../app/user/userSlice';

export const Home=() => {
  let history = useHistory();

  /**
   * ir a la pagina de todas las tiendas
   */
   const actionBtn = () =>{
    history.push("/new-account");
  }
  
  const activeStore = useSelector(storeActive);
  const button ={
    label:"Ver todas las cuentas",
    action: actionBtn
  }

  /**
   * descarga de instructivo
   */
  const pdf = () => {
    window.open('https://clientes.titamedia.com/master-c/manual/Manual-Master-Catalogue.pdf', '_blank')
  }

  return (
    <>
      {activeStore.rols && !activeStore.rols.find(permission => permission.id === 16) ? (
        <div className="home">
          <div className="home-section">
            <div style={{'width':'100%'}}>
              
              <div className="donwload">
                <h1>¡Bienvenido!</h1>
                <br></br>
                <button onClick={()=>{pdf()}}>Descargar el PDF</button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="home">
          <Accordion
            title = {'Crear nueva cuenta'}
            button = {button}
          >
            <Box>
              <CreateAccount></CreateAccount>
            </Box>
          </Accordion>
          <div className="home-section">
            <div>
              {/* <Accordion
                title = {'Video tutorial'}
              > */}
                {/* <Video
                  source="mov_bbb.mp4"
                  type="mp4"
                ></Video> */}
                <div className="donwload">
                  <button onClick={()=>{pdf()}}>Descargar el PDF</button>
                </div>
              {/* </Accordion> */}
            </div>
            <div>
              <Accordion
                title = {'Cuentas recientes'}
              >
                <Box>
                  <RecentAccounts></RecentAccounts>
                </Box>
              </Accordion>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
 