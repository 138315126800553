import { configureStore } from '@reduxjs/toolkit';
import userReducer from './user/userSlice';
import navReducer from './nav/navSlice';
import acountReducer from './acount/acountSlice';
import categoryTreeReducer from './categoryTree/categoryTreeSlice';
import productAttrReducer from './productAttr/productAttrSlice';
import filesReducer from './files/filesSlice';
import productBrandReducer from './productBrand/productBrandSlice';
import productAndSkuReducer from './productAndSku/productAndSkuSlice';
import productSpecsReducer from './productSpecs/productSpecsSlice';
import collectionsReducer from './collections/collectionsSlice';
import inventoryReducer   from './inventory/inventorySlice';
import priceReducer from './price/priceSlice';

export const store = configureStore({
  reducer: {
    user: userReducer,
    nav: navReducer,
    acount: acountReducer,
    categoryTree: categoryTreeReducer,
    productAttr: productAttrReducer,
    files: filesReducer,
    productBrand: productBrandReducer,
    productAndSku: productAndSkuReducer,
    productSpecs: productSpecsReducer,
    collections: collectionsReducer,
    inventory: inventoryReducer,
    price: priceReducer
  },
});
