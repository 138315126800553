import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { acountNew, acountList, acountDelete } from './acountAPI';


const initialState = {
  acountList:[],
  status: 'idle',
  selectAcount:{
    store:'',
    apiKey:'',
    accessToken:''
  },
  actionUI: 'new'
};


/**
 * 
 */
export const acountListAsync = createAsyncThunk(
  'acount/all',
  async () => {
    const response = await acountList();
    return  response.map((acount) => {
      return {
        ...acount,
        label: acount.store,
        disabled: false
      }
    })
  }
);

export const acountNewAsync = createAsyncThunk(
  'acount/new',
  async (data) => {
    const response = await acountNew(data.email, data.storeName, data.apiKey, data.apiToken);
    return  response
  }
);

export const acountDeleteAsync = createAsyncThunk(
  'acount/delete',
  async (data) => {
    const response = await acountDelete(data.storeName, data.email);
    return  response
  }
);


export const acountSlice = createSlice({
  name: 'acount',
  initialState,
  reducers: {
    setAcount: (state, action) => {
      state.selectAcount = action.payload;
      state.actionUI = 'edit';
    },
    empyAcount: (state) => {
      state.selectAcount = initialState.selectAcount;
      state.actionUI = initialState.actionUI;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(acountListAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(acountListAsync.fulfilled, (state, action) =>  {
        state.status = 'idle';
        state.acountList = action.payload
      })
      .addCase(acountListAsync.rejected, (state, action)=>{})
      .addCase(acountNewAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(acountNewAsync.fulfilled, (state, action) =>  {
        state.status = 'idle';
      })
      .addCase(acountNewAsync.rejected, (state, action)=>{})
      .addCase(acountDeleteAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(acountDeleteAsync.fulfilled, (state, action) =>  {
        state.status = 'idle';
      })
      .addCase(acountDeleteAsync.rejected, (state, action)=>{})
  },
});

export const { setAcount, empyAcount } = acountSlice.actions;

export const acountsList = (state) => state.acount.acountList;
export const selectAcount = (state) => state.acount.selectAcount;
export const actionUI = (state) => state.acount.actionUI;

export default acountSlice.reducer;
