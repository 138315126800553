import { axiosInstance } from "../../serviceConf";

export function downLoadCategoryTree( email, storeName, to ) {
  let url = `api/Category/categoryTreeDownload`
  let params = {
    email: email,
    origin: storeName,
    to: to
  }
  return new Promise((resolve, reject) =>
    axiosInstance.get(url,{ params })
    .then((response) => {
      resolve(response.data)
    }).catch((err)=>{
      reject(err)
    })
  )
}

export function updateCategoryTree( email, storeName, path ) {
  let url = `api/Category/updateMetaTitleAndMetaDescriptionFromFile`
  let params = {
    email: email,
    store: storeName,
    path: path
  }
  return new Promise((resolve, reject) =>
    axiosInstance.get(url,{ params })
    .then((response) => {
      resolve(response.data)
    }).catch((err)=>{ 
      reject(err)
    })
  )
}

export function createCategoryTree( email, storeName, path, update ) {
  let url = `api/Category/createCategoryTreeFromFile`
  let body = {
    email: email,
    store: storeName,
    path: path,
    update: update
  }
  return new Promise((resolve, reject) =>
    axiosInstance.post(url, body )
    .then((response) => {
      resolve(response.data)
    }).catch((err)=>{
      reject(err)
    })
  )
}

export function replicateCategoryTree( email, origin, destiny, update ) {
  let url = `api/Category/CategoryTreeTransfer`
  let body = {
    email: email,
    origin: origin,
    destiny: destiny,
    update: update
  }
  return new Promise((resolve, reject) =>
    axiosInstance.post(url, body )
    .then((response) => {
      resolve(response.data)
    }).catch((err)=>{
      reject(err)
    })
  )
}

export function scheduledCategoryTree( email, storeName, path, update, date ) {
  let dateGmt = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), 0));
  let url = `api/Category/chargeCatalog`
  let body = {
    email: email,
    store: storeName,
    path: path,
    update: update,
    date: dateGmt
  }
  return new Promise((resolve, reject) =>
    axiosInstance.post(url, body )
    .then((response) => {
      resolve(response.data)
    }).catch((err)=>{
      reject(err)
    })
  )
}