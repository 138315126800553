
import { axiosInstance2, axiosInstance } from "../../serviceConf";


 
export function priceFixedImport ( storeName, email, base  ) {
    let url = `/api/price/fixed`
    let body = {
      store: storeName,
      email: email,
      base: base
      
    }

    return new Promise((resolve, reject) =>
      axiosInstance2.post(url, body )
      .then((response) => {
        resolve(response.data)
      }).catch((err)=>{
        reject(err)
      })
    )
  }

export function priceImport ( storeName, email, base  ) {
  let url = `/api/price/`
  let body = {
    store: storeName,
    email: email,
    base: base
    
  }

  return new Promise((resolve, reject) =>
    axiosInstance2.post(url, body )
    .then((response) => {
      resolve(response.data)
    }).catch((err)=>{
      reject(err)
    })
  )
}

export function deletePrices2(email, storeName, file ) {
  let url = `ftp/images/uploadMasiveImages`
  let params = {
    email: email,
    store: storeName
  }
  let config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }
  const formData = new FormData();
  formData.append('file',file[0])

  return new Promise((resolve, reject) =>
    axiosInstance.get( url,formData,{params}, config )
    .then((response) => {
      resolve(response.data)
    }).catch((err)=>{
      reject(err)
    })
  )
}

async function encodeFileAsBase64URL (file){
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener('loadend', () => {
        resolve(reader.result);
    });
    reader.readAsDataURL(file);
  });
};


export async function deletePrices(email, storeName, file ) {
  console.log(">>>>>>>>>>>>>>>>> fiel", file)
  let fileBase64 =  await encodeFileAsBase64URL(file[0]);
  let url = `api/Price/deletePrices`
  let body = {
    email: email,
    store: storeName,
    base: fileBase64
  }

  return new Promise((resolve, reject) =>
    axiosInstance2.post( url, body )
    .then((response) => {
      resolve(response.data)
    }).catch((err)=>{
      reject(err)
    })
  )
}

export async function downLoadPrices(email, storeName, isFixed ) {
  let url = `api/Price/downloadPrices`
  let params = {
    email: email,
    store: storeName,
    isFixed: isFixed
  }
  return new Promise((resolve, reject) =>
    axiosInstance2.get(url, { params })
    .then((response) => {
      resolve(response.data)
    }).catch((err)=>{
      reject(err)
    })
  )
}