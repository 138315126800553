import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import './sass/App.scss';

import { Home } from './features/pages/home/Home';
import { Header } from './features/components/header/Header';
import { Nav } from './features/components/nav/Nav';
import { Login } from './features/pages/login/Login';
import { CategoryTree } from './features/pages/categoryTree/CategoryTree';
import { NewAttrAndSku } from './features/pages/newAttrAndSku/NewAttrAndSku';
import { NewProductBrands } from './features/pages/newProductBrands/NewProductBrands';
import { DownLoadCategoryTree } from './features/pages/downLoadCategoryTree/DownLoadCategoryTree';
import { DownLoadAttrAndSku } from './features/pages/downLoadAttrAndSku/DownLoadAttrAndSku';
import { ChangeCategoryTree } from './features/pages/changeCategoryTree/ChangeCategoryTree';
import { CreateProductAndSku } from './features/pages/createProductAndSku/CreateProductAndSku';
import { DownLoadProductAttr } from './features/pages/downLoadProductAttr/DownLoadProductAttr';
import { LoadProductSpecs } from './features/pages/loadProductSpecs/LoadProductSpecs';
import { DownLoadSkuAttr } from './features/pages/downLoadSkuAttr/DownLoadSkuAttr';
import { LoadSkuSpecs } from './features/pages/loadSkuSpecs/LoadSkuSpecs';
import { LoadImg } from './features/pages/loadImg/LoadImg';
import { ReplyCategoryTree } from './features/pages/replyCategoryTree/ReplyCategoryTree';
import { ReplyBrands } from './features/pages/replyBrands/ReplyBrands';
import { NewUser } from './features/pages/newUser/NewUser';
import { NewAccount } from './features/pages/newAccount/NewAccount';
import { ChangeCollections } from './features/pages/changeCollections/ChangeCollections';
import { ChangeProductAndSku } from './features/pages/changeProductAndSku/ChangeProductAndSku';
import { ToastContainer } from 'react-toastify';

import { Loader } from './features/ui/loader/Loader';
import { DownLoadBrands } from './features/pages/downLoadBrands/DownLoadBrands';
import { ChangeInventory } from './features/pages/changeInventory/ChangeInventory';
import { LoadPrice } from './features/pages/loadPrice/LoadPrice';
import { DeleteImg } from './features/pages/DeleteImg/DeleteImg';
import { DeletePrices } from './features/pages/DeletePrices/DeletePrices';



function App() {
  return (
    <div className="app">
      <Router>
        <PrivateComponent path="/">
          <Nav/>
        </PrivateComponent>
        <div className="app-container">
          <PrivateComponent path="/">
            <Header/>
          </PrivateComponent>  
          <Switch>
            <ProtectedRoute exact path="/">
              <Login/>
            </ProtectedRoute>
            <ProtectedRoute exact path="/login">
              <Login/>
            </ProtectedRoute>
              <PrivateRoute exact path="/home">
                <Home/>
              </PrivateRoute>
              <PrivateRoute exact path="/category-tree">
                <CategoryTree/>
              </PrivateRoute>
              <PrivateRoute exact path="/new-attr-and-sku">
                <NewAttrAndSku/>
              </PrivateRoute>
              <PrivateRoute exact path="/new-product-brands">
                <NewProductBrands/>
              </PrivateRoute>
              <PrivateRoute exact path="/download-category-tree">
                <DownLoadCategoryTree/>
              </PrivateRoute>
              <PrivateRoute exact path="/download-attr-and-sku">
                <DownLoadAttrAndSku></DownLoadAttrAndSku>
              </PrivateRoute>
              <PrivateRoute exact path="/change-category-tree">
                <ChangeCategoryTree/>
              </PrivateRoute>
              <PrivateRoute exact path="/create-product-and-sku">
                <CreateProductAndSku/>
              </PrivateRoute>
              <PrivateRoute exact path="/download-product-attr">
                <DownLoadProductAttr/>
              </PrivateRoute>
              <PrivateRoute exact path="/load-product-specs">
                <LoadProductSpecs/>
              </PrivateRoute>
              <PrivateRoute exact path="/download-sku-attr">
                <DownLoadSkuAttr/>
              </PrivateRoute>
              <PrivateRoute exact path="/load-sku-specs">
                <LoadSkuSpecs/>
              </PrivateRoute>
              <PrivateRoute exact path="/load-img">
                <LoadImg/>
              </PrivateRoute>
              <PrivateRoute exact path="/reply-category-tree">
                <ReplyCategoryTree/>
              </PrivateRoute>
              <PrivateRoute exact path="/reply-brands">
                <ReplyBrands/>
              </PrivateRoute>
              <PrivateRoute exact path="/new-user">
                <NewUser/>
              </PrivateRoute>
              <PrivateRoute exact path="/new-account">
                <NewAccount/>
              </PrivateRoute>
              <PrivateRoute exact path="/change-collections">
                <ChangeCollections/>
              </PrivateRoute>
              <PrivateRoute exact path="/change-product-and-sku">
                <ChangeProductAndSku/>
              </PrivateRoute>
              <PrivateRoute exact path="/download-brands">
                <DownLoadBrands/>
              </PrivateRoute>
              <PrivateRoute exact path="/change-inventory">
                <ChangeInventory/>
              </PrivateRoute>
              <PrivateRoute exact path="/load-price">
                <LoadPrice/>
              </PrivateRoute>
              <PrivateRoute exact path="/delete-img">
                <DeleteImg/>
              </PrivateRoute>
              <PrivateRoute exact path="/delete-price">
                <DeletePrices/>
              </PrivateRoute>
          </Switch>
        </div>
      </Router>
      
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme= "dark"
      />
      <Loader/>
    </div>
    
  );
}

const PrivateComponent = ({ children, ...rest }) => {
  return (
    <Route
      {...rest}
      render={({ location }) =>{
        if(sessionStorage.getItem('token')){
          return children
        }else{
          return ''
        }
      }}
    />
  );
}

const ProtectedRoute = ({ children, ...rest }) => {
  return (
    <Route
      {...rest}
      render={({ location }) =>{
        if(!sessionStorage.getItem('token')){
          return children
        }else{
          return <Redirect
            to={{
              pathname: "/home",
              state: { from: location }
            }}
          />
        }
      }}
    />
  );
}

const PrivateRoute = ({ children, ...rest }) => {

  return (
    <Route
      {...rest}
      render={({ location }) =>{
        if(sessionStorage.getItem('token')){
          return <div className="app-content">{children}</div>
        }else{
          return <Redirect
            to={{
              pathname: "/login",
              state: { from: location }
            }}
          />
        }
      }}
    />
  );
}

export default App;
