import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import jwt_decode from "jwt-decode"

import { toast } from 'react-toastify';

import {
  loginAsync,
} from '../../../app/user/userSlice';

import logo from '../../../images/logo.svg';

export const Login=() => {
  
  const dispatch = useDispatch();
  let history = useHistory();

  /**
   * acción delogin
   * @param {*} response respuesta de google
   */
  const responseGoogle = (response) => {
    console.log(">>>>>>>>>>, response", response);
    let userDecode = jwt_decode(response.credential)
    console.log(">>>>>>>>>>, userDecode", userDecode);
    dispatch(loginAsync(userDecode)).then((res)=>{
      if(res.error){
        toast.error(res.error.message)
      }else{
        history.push("/home");
      }
   })
  }

  /**
   * seteo de la variable global de google 
   */
  useEffect( () => {
    window.google.accounts.id.initialize({
      client_id: "1034845870168-jq41df8gs65e5at4dvo1m9tr91eb5i51.apps.googleusercontent.com",
      callback: responseGoogle
    })
    window.google.accounts.id.renderButton(
      document.getElementById("signInDiv"),
      {
        type: "standard",
        theme:"outline",
        size: "large",
        text: "continue_with",
        width: 262,
        shape: "rectangular"
      }
    )
  },[])

  return (
    <div className="login">
      <div className="container">
        <div className="login-cover">
          <img src={logo} alt='logo'/>
        </div>
        <div className="login-action">
          <h5>Iniciar Sesión</h5>
          <div id="signInDiv"></div>
        </div>
      </div>
    </div>
  );
}
 