import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from "../../ui/modal/Modal";
import { UserAcounts } from '../userAcounts/UserAcounts';
import { modalFooter } from "../../ui/modal/ModalShema";

import { toast } from 'react-toastify';

import {
  userForm,
  actionUI,
  setUser,
  userSaveAsync,
  userListAsync,
  empyUser,
  userEditAsync,
  selectUser
} from '../../../app/user/userSlice';

export const AddUser=(props) => {

  const dispatch = useDispatch();
  const user = useSelector(selectUser);

  const formUser = useSelector(userForm);
  const [email, setEmail] = useState(formUser.email)

  const actionActive = useSelector(actionUI);

  const [stateModal, setStateModal] = useState(false)

  const acctions = modalFooter

  const [filterAcounts, setFilterAcounts] = useState('')

  useEffect(() => {
    setEmail(formUser.email)
  }, [formUser]);

  /**
   * pobla el objeto userSelected del store
   * @param {Object} data fila de la tabla que contiene la cuenta a editar
   */
  const updateUser = (data) => {
    dispatch(setUser({
      email:email,
      acounts: data
    }))
  }

  /**
   * validación de llenado de los campos necesarios del formulario
   * @returns booleano con el resultado
   */
  const disabled = () =>{
    if(email && formUser.acounts &&
      formUser.acounts.length > 0 && 
      formUser.acounts[formUser.acounts.length-1].rols.find(rol =>  rol.state === true) && 
      formUser.acounts[formUser.acounts.length-1].store){
        return false
      }
    return true
  }

  /**
   * guardado / editado de un usuario
   */
  const save = () => {
    let saveRols = formUser.acounts.map((store) => {
      let newRols = store.rols.map((rol)=>{
        if(rol.state === true){
          return {
            id: rol.backId,
            name: rol.backName
          }
        }
      })
      return {
        store: store.store,
        rols: newRols.filter(Boolean)
      }
    })
    let userSend = {
      user:{
        email:formUser.email,
        perStore: saveRols
      },
      email: user.email
    }
    let sendText = 'creado'
    let actienSend = userSaveAsync
    if(actionActive !== 'new'){
      actienSend = userEditAsync
      sendText = 'editado'
    }

    dispatch(actienSend(userSend)).then((response)=>{
      if(response.error){
        toast.error(response.error.message)
      }else{
        dispatch(userListAsync())
        dispatch(empyUser())
        toast.success(`Usuario ${sendText} con éxito`)
        setStateModal(false)
      }
    })
  }

  return (
    <form className="add-user">
      <div className="form-actions-row">
        <label>Correo:</label>
        <input 
          placeholder="Ingresa el correo electrónico" 
          value={email} 
          onChange={e => setEmail(e.target.value)}
          disabled={actionActive !== 'new'}
        />
        <button type="button" className="secondary-btn" onClick={()=>{setStateModal(true)}} disabled={email.length === 0}>Agregar Cuentas</button>
      </div>
      <Modal
        open={stateModal}
        setState={setStateModal}
        footer={{
          cancel:{
            ...acctions.cancel,
            text: 'Cancelar',
            action: ()=>{dispatch(empyUser())}
          },
          send:{
            ...acctions.send,
            text: actionActive === 'new'? 'Crear':'Editar',
            action: ()=>{save()},
            disabled:disabled()
          }
        }}
        search={{
          set:setFilterAcounts, 
          value:filterAcounts,
          placeholder:"Buscar Cuentas"
        }}>
        <UserAcounts acounts={formUser.acounts} updateAcounts={updateUser} filter={filterAcounts}></UserAcounts>
      </Modal>
    </form>
  );
}
 