import { axiosInstance } from "../../serviceConf";

export function createProductSpecs( email, storeName, path ) {
  let url = `api/Category/createProductSpecificationFromFile`
  let body = {
    email: email,
    store: storeName,
    path: path
  }
  return new Promise((resolve, reject) =>
    axiosInstance.post(url, body )
    .then((response) => {
      resolve(response.data)
    }).catch((err)=>{
      reject(err)
    })
  )
}

export function scheduledProductSpecs( email, storeName, path, update, date ) {
 
  let dateGmt = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), 0));
  let url = `api/Category/chargeProdruct`
  let body = {
    email: email,
    store: storeName,
    path: path,
    update: update,
    date: dateGmt
  }
  return new Promise((resolve, reject) =>
    axiosInstance.post(url,body )
    .then((response) => {
     resolve(response.data)
    
    }).catch((err)=>{
      reject(err)
    })
   
  )
}