import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { downLoadProductSpecifications, createProductSpecifications, dowloadProductAttr } from './productAttrAPI';


const initialState = {
  status: 'idle',
  selectedStoreDownload:{
    store:'',
    to:''
  },
};


/**
 * 
 */
export const downLoadAsync = createAsyncThunk(
  'productAttr/downLoad',
  async (data) => {
    const response = await downLoadProductSpecifications(data.email, data.storeName);
    const url = window.URL
        .createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `descarga.xlsx`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    return 'OK'
  }
);

export const createAsync = createAsyncThunk(
  'productAttr/create',
  async (data) => {
    const response = await createProductSpecifications(data.email, data.storeName, data.sku, data.path);
    return  response
  }
);

export const downLoadAttrAsync = createAsyncThunk(
  'productAttr/downLoadAttr',
  async (data) => {
    const response = await dowloadProductAttr(data.email, data.storeName, data.to);
    return  response
  }
);


export const productAttrSlice = createSlice({
  name: 'acount',
  initialState,
  reducers: {
    setStore: (state, action) => {
      state.selectedStoreDownload = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(downLoadAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(downLoadAsync.fulfilled, (state, action) =>  {
        state.status = 'idle';
      })
      .addCase(downLoadAsync.rejected, (state, action)=>{})
      .addCase(createAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createAsync.fulfilled, (state, action) =>  {
        state.status = 'idle';
      })
      .addCase(createAsync.rejected, (state, action)=>{})
      .addCase(downLoadAttrAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(downLoadAttrAsync.fulfilled, (state, action) =>  {
        state.status = 'idle';
      })
      .addCase(downLoadAttrAsync.rejected, (state, action)=>{})
  },
});


export const selectDownload = (state) => state.productAttr.selectedStoreDownload;

export default productAttrSlice.reducer;
