import React from 'react'

export const DownLoadFormat = (props) => {
  return (
    <div className="download-format">
    <i className="icon icon-alert"></i>
    {
      props.formats &&
      
      <p>Si aún no cuentas con el formato para descargar el archivo
        {
          props.formats.map((format, index) =>{
            return (
           
              <i key={index}> haz <a className="link" href={format.url}>click aquí</a> para descargarlo
                {format.type ? ` de ${format.type}` : ''}
                {index < props.formats.length -1 ? ' o ': ''}
              </i>
            
            )
          } )
        }
      </p>
    }
  </div>
  )
}
