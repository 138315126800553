import React from 'react';


export const AddImg=(props) => {

  /**
   * envío de la acción enviada desde de el componente padre 
   */
  const action = () =>{
    if (props.send) props.send()
  }

  return (
    <form className="load-category-tree">
      <div className={`form-actions-row`}>
        <button className="secondary-btn" type='button' onClick={()=>{action()}}>Enviar</button>
      </div>
    </form>
  );
}
 