import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Table } from '../../ui/table/Table';
import { tableTypes, actionTypes } from '../../ui/table/TableShema';

import { toast } from 'react-toastify';

import {
  downLoadAsync,
  deleteScheduledAsync,
  listScheduledAsync,
  listScheduled
} from '../../../app/files/filesSlice';

export const FilesScheduled=(props) => {

  const dispatch = useDispatch();
  const filesList = useSelector(listScheduled);

  const tableSizes =[4,4,4,0.4];
  const [rowActive, setRowActive] = useState()

  useEffect(() => {
    dispatch(listScheduledAsync({category: props.category}))
  }, []);

  /**
   * se realiza copia de la data para poderla modificar
   * @returns copia de la data
   */
  const populateData = (active) =>{
    
    return filesList.map((file, index) => {
      //let date = file.date.toLocaleString('en-US', { timeZone: 'America/Bogota' })
      let hours = new Date(file.date).getHours()
      let minutes = new Date(file.date).getMinutes()
      return {
        ...file,
        dateString: `${new Date(file.date).toLocaleDateString()}`,
        time: `${hours < 9 ? '0' : ''}${hours}:${minutes < 9 ? '0' : ''}${minutes}`,
        index: index
      }
    })
  }

  /**
   * elimina un archivo
   * @param {Object} data fila de la tabla que contiene el archivo a eliminar
   */
  const deleteFile = (e, data) => {
    e.stopPropagation()
    dispatch(deleteScheduledAsync({
      jobName:data.jobName,
      jobGroup:data.jobGroup
    })).then((response)=>{
      if(response.error){
        toast.error(response.error.message)
      }else{
        dispatch(listScheduledAsync ({category:props.category}))
        toast.success("Archivo eliminado con éxito")
      }
    })
  }


  /**
   * modifica la columna acciones de la tabla
   * @param {Object} data fila de la tabla
   * @returns celda modificada
   */
  const templateActions = (data) =>{
    return (
      <div className='table-actions'>
        <i 
          className={
            `icon icon-s icon-action ${rowActive === data.index ? 'icon-arrow-up': 'icon-arrow-down'}`
          }
        >
        </i>
      </div>
    )
  }

  /**
   * modifica la columna archivo de la tabla
   * @param {Object} data fila de la tabla
   * @returns celda modificada
   */
  const templateFile = (data) =>{
    return (
      <div>
        <h4>Nombre archivo:</h4>
        <a className="file large">
          {data.file}
        </a>
      </div>
    )
  }

  /**
   * modifica la columna store de la tabla
   * @param {Object} data fila de la tabla
   * @returns celda modificada
   */
   const templateStore = (data) =>{
    return (
      <div>
        <h4>Cuenta:</h4>
        <p className="large">
          {data.store}
        </p>
      </div>
    )
  }

  /**
   * modifica la columna date de la tabla
   * @param {Object} data fila de la tabla
   * @returns celda modificada
   */
   const templateDate = (data) =>{
    return (
      <div>
        <h4>Fecha:</h4>
        <p className="large">
          {data.dateString}
        </p>
      </div>
    )
  }

  const headers =[
    {name:'', key:'file',},
    {name:'', key:'store'},
    {name:'', key:'dateString'},
    {name:'', key:'actions'}
  ]

  const templates = [
    {
      key: 'actions',
      render: templateActions
    },
    {
      key: 'file',
      render: templateFile
    },
    {
      key: 'store',
      render: templateStore
    },
    {
      key: 'dateString',
      render: templateDate
    }
  ]

  /**
   * indica la selda desplegada 
   * @param {*} index numero de la celda desplegada
   */
  const setActive = (index) =>{
    setRowActive(index)
  }

  /**
   * accion de expandir la tabla 
   * @param {*} data fila de la tabla
   * @returns html a agregar a la fila 
   */
  const tableAction = (data) =>{
    let currentDate = new Date()
    currentDate.setHours(currentDate.getHours(), currentDate.getMinutes()-2)
    return (
      <div className="moreInfo">
        <div style={{'flex':`${tableSizes[0]} 0`}}>
          <h4>Ruta:</h4>
          <p>{data.path}</p>
        </div>
        <div style={{'flex':`${tableSizes[1]} 0`}}>
          <h4>Tipo:</h4>
          <p>{data.jobGroup}</p>
        </div>
        <div style={{'flex':`${tableSizes[2]} 0`}}>
          <h4>hora:</h4>
          <p>{data.time}</p>
        </div>
        <div className='table-actions' style={{'flex':`${tableSizes[3]} 0`}}>
          <i 
            className={
              `icon icon-delete-color ${currentDate.toUTCString() < data.date ? ' icon-disabled ': ' icon-action '}`
            } 
            onClick={(e) => { if(currentDate.toUTCString() > data.date) deleteFile(e, data) }}
          >
          </i>
        </div>
      </div>
    )
  }

  return (
    <div className="files-scheduled">
      <h3>Programación de cargue de información</h3>
      {
        filesList.length > 0 ? 
        <Table
          data={populateData()} 
          headers={headers} 
          templates={templates}
          sizes = {tableSizes}
          type={tableTypes.CARD}
          action={{
            type:actionTypes.OPEN_ROW,
            func : tableAction,
            retrieveIndex: setActive
          }}
        >
        </Table> :
        <div className='no-results'>
          Sin resultados, Si deseas programar el cargue de información, haz click en el enlace de arriba
        </div>
      }
    </div>
  );
}
 