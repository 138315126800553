import React, {useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Box } from "../../ui/box/Box";
import { Title } from '../../ui/title/Title';
import { LoadFile } from '../../components/loadFile/LoadFile';
import { FilesLoaded } from '../../components/filesLoaded/FilesLoaded';
import { Back } from '../../components/back/Back';
import { LoadCategoryTree } from '../../components/loadCategoryTree/LoadCategoryTree';
import { selectUser, storeActive } from '../../../app/user/userSlice';

import { Modal } from '../../ui/modal/Modal';
import { FilesScheduled} from '../../components/filesScheduled/FilesScheduled';
import {listScheduledAsync } from '../../../app/files/filesSlice';

import { toast } from 'react-toastify';

import {
  changeAsync,
  downLoadAsync
} from '../../../app/collections/collectionsSlice';


export const ChangeCollections=() => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const store = useSelector(storeActive);
  const [stateModal, setStateModal] = useState(false)

  const formats =[
    {url:'https://clientes.titamedia.com/master-c/formatos/Modificar/modificar-colecciones.xlsx'}
  ]

  /**
   * envio de archivo
   * @param {*} file archivo a enviar
   * @returns 
   */
  const send = (file) => {
    return new Promise((resolve, reject) => {
      dispatch(changeAsync({
        email:user.email, 
        storeName:store.store, 
        path: file.path,
        update: true
      })).then((response)=>{
       if(response.error){
        toast.error(response.error.message)
        }else{
          resolve()
          toast.success("Colecciones cargadas con éxito")
        }
      })
    })
  }

  /**
   * programación del envío del archivo  
   * @param {*} file archivo a enviar
   * @param {*} date fecha de programación 
   * @returns 
   */
  const scheduled = (file, date) => {
    return new Promise((resolve, reject) => {
      dispatch(downLoadAsync({
        email:user.email, 
        storeName:store.store, 
        path: file.path,
        update: true,
        date: date
      })).then((response)=>{
      
      if(response.error){
        toast.error(response.error.message)
        }else{
          dispatch(listScheduledAsync({category:'coleccion'}))
          setStateModal(false)
          toast.success("Modificacion de colecciones programado con éxito")
        }
      })
    })
  }

  /**
   * descaraga de las colecciones
   * @returns 
   */
  const downLoad =() => {
    return new Promise((resolve, reject) => {
      dispatch(downLoadAsync({
        email:user.email, 
        storeName:store.store
      })).then((response)=>{
        if(response.error){
          toast.error(response.error.message)
        }else{
          toast.success("Colecciones descargadas con éxito")
        }
      })
    })
  }
  
  return (
    <div className="category-tree">
      <Back></Back>
      <Title title="Modificar y descargar colecciones"></Title>

      <Box>
        <LoadFile category={'MC_'} type={'uploads'} formats={formats}></LoadFile>
      </Box>
      <FilesLoaded type={'uploads'} category={'MC_'}></FilesLoaded>

      <Box>
        <LoadCategoryTree send={send} ></LoadCategoryTree>
      </Box>
      <p> 
        Si deseas programar el cargue de información, haz <a className="link" onClick={()=>{setStateModal(true)}}>click aquí</a>
      </p>
      
      <FilesScheduled category={'coleccion'}></FilesScheduled>

      <p> 
        Si deseas descargar las colecciones, haz <a className="link" onClick={()=>{downLoad()}}>click aquí</a>
      </p>

      <Modal
        open={stateModal}
        setState={setStateModal}>
        <LoadCategoryTree send={scheduled} programming></LoadCategoryTree>
      </Modal>
      
    </div>
  );
}
 