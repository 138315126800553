import React, { useState, useRef, useEffect } from 'react';


export const InputFile=(props) => {

  const inputFile = useRef();

  const [file, setFile] = useState()

  useEffect(() => {
    inputFile.current.addEventListener( 'change', ( event ) =>{
      setFile(event.target?.files[0]?.name)
      props.setValue(event.target?.files)
    })
  }, []);
  
  useEffect(() => {
   if(!props.value[0]) setFile('')
  }, [props.value]);

  /**
   * genera un click en el input oculto cuando se realiza un evento en otro elemento
   * @param {*} event evento ejecutado en otro elemento del dom
   */
  const trigger =(event)=>{
    event.preventDefault();
    inputFile.current.click()
  }

  return (
    <div className="input-file">
      <button onClick={(event)=>{trigger(event)}}>Examinar</button>
      <div onClick={(event)=>{trigger(event)}} className={`container-file ${!file ? 'placeholder' : ''}`}>
        {
          !file ? 
          <p>Selecciona un archivo para cargar</p> :
          <p>{file}</p>
        }
      </div>
      <input type="file" ref={inputFile}/>
    </div>
  );
}