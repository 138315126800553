import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";

import {
  logOut,
  populateUser,
  selectUser,
  storeActive
} from '../../../app/user/userSlice';


export const Header=() => {
  
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const rol = useSelector(storeActive);
  let history = useHistory();

  /**
   * elimina la sesión
   */
  const userLogOut = () => {
    dispatch(logOut())
    history.push("/login");
  }

  /**
   * ir a home
   */
  const goToHome = () => {
    history.push("/home");
  }

  /**
   * ir a uranus
   */
  const goToUranus = () => {
    window.open('https://proyectox.titamedia.com', '_blank')
  }

  useEffect(() => {
    dispatch(populateUser())
  }, []);

  return (
    <header>
      <div className="breadcrumbs">
        <i className="icon icon-home icon-action" onClick={()=>{goToHome()}}></i>
        <i className="icon icon-u icon-action"  onClick={()=>{goToUranus()}}></i>
      </div>
      <div className="user">
        <img className="photo" src={user.picture} alt={user.name?.substring(0,1)}/>
        <div className="user-data">
          <p className="name">{user.name}</p>
          <p className="role">{rol.rols ? rol.rols[0].rol : 'no rol'}</p>
        </div>
        <i className="icon icon-exit icon-action icon-m" onClick={()=>{userLogOut()}}></i>
      </div>
    </header>
  );
}
 