import React from 'react'


import { Back } from '../../components/back/Back'
import { DownLoadFormat } from '../../components/downLoadFormat/DownLoadFormat'
import { Inventory } from '../../components/inventory/Inventory'
import { Box } from '../../ui/box/Box'
import { Title } from '../../ui/title/Title'

export const ChangeInventory = () => {

  const formats =[
    {url:'https://clientes.titamedia.com/master-c/formatos/Inventario/Formato-cargue-inventario.xlsx'}
  ]

  return (
    <div className="category-tree">
        <Back></Back>
        <Title title="Actualización de inventario"> </Title>

        <Box>
           <DownLoadFormat formats={formats}></DownLoadFormat>
        </Box>

        <Inventory />
    </div>
  )
}
