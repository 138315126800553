import React from 'react';


export const HeaderCell=(props) => {

  return (
    <div className="header-cell" style={{'flex':`${props.size} 0`}}>
      {props.cell.name}
    </div>
  );
}