import { axiosInstance } from "../../serviceConf";

export function changeCollection( email, storeName, path, update ) {
  let url = `api/Category/update/collect`
  let body = {
    email: email,
    store: storeName,
    path: path,
    update: update
  }
  return new Promise((resolve, reject) =>
    axiosInstance.post(url, body )
    .then((response) => {
      resolve(response.data)
    }).catch((err)=>{
      reject(err)
    })
  )
}


export function scheduledCollection( email, storeName, path, update, date ) {
  let dateGmt = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), 0));
  let url = `api/Category/update/collectcharge`
  let body = {
    email: email,
    store: storeName,
    path: path,
    update: update,
    date: dateGmt
  }
  return new Promise((resolve, reject) =>
    axiosInstance.post(url, body )
    .then((response) => {
    
      resolve(response.data)
    }).catch((err)=>{
      reject(err)
    })
  )
}

export function downLoadCollection( email, storeName ) {
  let url = `api/Collection/collectionsDownload?origin=${storeName}`
  return new Promise((resolve, reject) =>
    axiosInstance.get(url,{ responseType: 'blob'})
    .then((response) => {
      resolve(response.data)
    }).catch((err)=>{
      reject(err)
    })
  )
}