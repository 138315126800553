import React, {useEffect} from 'react';
import { Table } from '../../ui/table/Table';
import { actionTypes, tableTypes } from '../../ui/table/TableShema';
import { useSelector, useDispatch } from 'react-redux';

import { selectUser } from '../../../app/user/userSlice';

import { toast } from 'react-toastify';

import {
  acountsList,
  acountListAsync,
  setAcount,
  acountDeleteAsync
} from '../../../app/acount/acountSlice';

export const RecentAccounts=() => {
  const dispatch = useDispatch();
  const acounts = useSelector(acountsList);
  const user = useSelector(selectUser);

  useEffect(() => {
    dispatch(acountListAsync())
  }, []);

  /**
   * pobla el objeto acountSelected del store
   * @param {Object} data fila de la tabla que contiene la cuenta a editar
   */
  const edit = (e, data)=>{
    e.stopPropagation()
    dispatch(setAcount({
      ...data,
      accessToken: data.apiToken
    }))
    window.scroll({top: 0});
  }

  /**
   * elimina una cuenta
   * @param {Object} data fila de la tabla que contiene la cuenta a eliminar
   */
  const deleteAcount = (e, data)=>{
    e.stopPropagation()
    dispatch(acountDeleteAsync({
      storeName:data.store, 
      email:user.email
    })).then((response)=>{
      if(response.error){
        toast.error(response.error.message)
      }else{
        dispatch(acountListAsync())
        toast.success("Tienda eliminada con éxito")
      }
    })
  }

  /**
   * modifica la columna acciones de la tabla
   * @param {Object} data fila de la tabla
   * @returns celda modificada
   */
  const templateActions = (data) =>{
    return (
      <div>
        {/* <i className="icon icon-star-color icon-action icon-m" onClick={() => {action(data.name)}}></i> */}
        <i className="icon icon-edit-color icon-action icon-m"  onClick={(e) => {edit(e, data)}}></i>
        <i className="icon icon-delete-color icon-action icon-m" onClick={(e) => {deleteAcount(e, data)}}></i>
      </div>
    )
  }

  /**
   * modifica la columna nombre de la tabla
   * @param {Object} data fila de la tabla
   * @returns celda modificada
   */
  const templateName = (data) =>{
    return (
      <div className="name">
        <img></img>
        {data.store}
      </div>
    )
  }

  /**
   * se realiza copia de la data para poderla modificar
   * @returns copia de la data
   */
  const populateData = () =>{
    return acounts.map((acount) => {
      return {
        ...acount
      }
    })
  }

  const headers =[
    {name:'Nombre de la cuenta', key:'store'},
    {name:'Acciones', key:'actions'}
  ]

  const templates = [
    {
      key: 'actions',
      render: templateActions
    },
    {
      key: 'store',
      render: templateName
    }
  ]

  /**
   * acción que se ejecuta al dar click en una fila de la tabla
   * @param {Object} data fila de la tabla
   * @returns contenido a mostrar
   */
  const tableAction = (data) =>{
    return (
      <div className="moreInfo">
        <label>Cuenta:</label>
        <p>{data.store}</p>
        <label>Access Key:</label>
        <p>{data.apiKey}</p>
        <label>Access Token:</label>
        <p>{data.apiToken}</p>
      </div>
    )
  }

  return (
    <div className="recent-accounts">
      <Table 
        data={populateData()} 
        headers={headers} 
        templates={templates}
        sizes = {[1.8,1]}
        action={{
          type:actionTypes.OPEN_ROW,
          func : tableAction
        }}
        type={tableTypes.NO_BORDER}
        itemsPerPage={5}
      ></Table> 
    </div>
  );
}
 