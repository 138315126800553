import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Box} from "../../ui/box/Box"

import { Title } from '../../ui/title/Title'
import { Back } from '../../components/back/Back'
import { DownLoadCategory } from '../../components/downLoadCategory/DownLoadCategory'

import { selectUser, storeActive } from '../../../app/user/userSlice'

import { toast } from 'react-toastify'

import { 
  downLoadAsync 
} from '../../../app/productBrand/productBrandSlice'


export const DownLoadBrands = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const store = useSelector(storeActive);

  /**
   * descarga de archivo
   * @returns 
   */
  const downLoad = () => {
    return new Promise((resolve, reject)=>{
      dispatch(downLoadAsync({
        email: user.email,
        origin: store.store
      })).then((response)=>{
        if(response.error){
          toast.error(response.error.message)
        }else{
          toast.success("Archivo descargado con exito")
          resolve()
        }
      })
    })
  }


  return (
    <div className="category-tree">
        <Back></Back>
        <Title title="Descargar Marcas"></Title>
        <Box>
           <DownLoadCategory send={downLoad}></DownLoadCategory>
        </Box>
    </div>
  )
}

