import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  active: '',
  status: 'idle',
};

export const navSlice = createSlice({
  name: 'nav',
  initialState,
  reducers: {
    //coloca el item activo
    setPath: (state, action) => {
      state.active = action.payload;
    },
  },
  extraReducers: (builder) => {
  },
});

export const { setPath } = navSlice.actions;

export const getPath = (state) => state.nav.active;


export default navSlice.reducer;
