import React from 'react';

export const Module=(props) => {

  /**
   * cambio de estado de el icono
   * @param {number} index posicion del icono dentro del arreglo  
   */
  const togglStateModule = (index) =>{
    if(props.set) props.set(index)
  }

  return (
    <div className='module-container' onClick={()=>{togglStateModule()}}>
      <div className={`module ${props.state ? 'active': ''}`}>
        <i className={`icon icon-${props.large ? props.large :'s'} icon-action icon-${props.icon} ${props.state ? 'active': ''}`}></i>
      </div>
      <p>{props.name}</p>
    </div>
  );
}
 