import { axiosInstance } from "../../serviceConf";

export function login(email) {
  let url = `login/validate`
  let params = {
    email: email
    //email: 'jcardenas@titamedia.com'
  }
  return new Promise((resolve, reject) =>
    axiosInstance.get(url,{ params })
    .then((response) => {
      resolve(response.data)
    }).catch((err)=>{
      reject(err)
    })
  )
}

export function userList() {
  let url = `api/User/findall`
  return new Promise((resolve, reject) =>
    axiosInstance.get(url,{})
    .then((response) => {
      resolve(response.data)
    }).catch((err)=>{
      reject(err)
    })
  )
}

export function userById(id) {
  let url = `api/User/findbyid/${id}`
  return new Promise((resolve, reject) =>
    axiosInstance.get(url,{})
    .then((response) => {
      resolve(response.data)
    }).catch((err)=>{
      reject(err)
    })
  )
}

export function deleteUser(id, email) {
  let url = `api/User/delete`
  let params = {
    email: email,
    id: id
  }
  return new Promise((resolve, reject) =>
    axiosInstance.delete(url,{params})
    .then((response) => {
      resolve(response.data)
    }).catch((err)=>{
      reject(err)
    })
  )
}

export function rolsByEmail(email) {
  let url = `api/User/accountsAllowed`
  let params = {
    email: email
  }
  return new Promise((resolve, reject) =>
    axiosInstance.get(url,{params})
    .then((response) => {
      resolve(response.data)
    }).catch((err)=>{
      reject(err)
    })
  )
}

export function saveUser(user, email) {
  let url = `api/User/save`
  let body = user
  let params = {
    email: email
  }
  
  return new Promise((resolve, reject) =>
    axiosInstance.post(url,body, {params})
    .then((response) => {
      resolve(response.data)
    }).catch((err)=>{
      reject(err)
    })
  )
}

export function editUser(user, email) {
  let url = `api/User/save`
  let body = user
  let params = {
    email: email
  }
  return new Promise((resolve, reject) =>
    axiosInstance.post(url,body, {params})
    .then((response) => {
      resolve(response.data)
    }).catch((err)=>{
      reject(err)
    })
  )
}