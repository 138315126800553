import { axiosInstance } from "../../serviceConf";

export function createProductBrands( email, storeName, path ) {
  let url = `api/Brand/createBrands`
  let params = {
    email: email,
    store: storeName,
    path: path
  }
  return new Promise((resolve, reject) =>
    axiosInstance.get(url,{ params })
    .then((response) => {
      resolve(response.data)
    }).catch((err)=>{
      reject(err)
    })
  )
}

export function downloadProductBrands(origin, email ) {
  let url = `api/Brand/brandsDownload`
  let params = {
    origin: origin,
     email: email
  }
  return new Promise((resolve, reject) =>
    axiosInstance.get(url,{ params, responseType: 'blob'},)
    .then((response) => {
     resolve(response.data)  
    }).catch((err)=>{
      reject(err)
    })
  )
}

export function replicateBrands( email, origin, destiny ) {
  let url = `api/Brand/BrandsTransfer`
  let body = {
    email: email,
    origin: origin,
    destiny: destiny
  }
  return new Promise((resolve, reject) =>
    axiosInstance.post(url, body )
    .then((response) => {
      resolve(response.data)
    }).catch((err)=>{
      reject(err)
    })
  )
}