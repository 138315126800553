import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { login, userList, userById, deleteUser, rolsByEmail, saveUser, editUser } from './userAPI';


const initialState = {
  user : {},
  userList:[],
  stores: [],
  status: 'idle',
  storeActive:{},
  userSelected:{
    email:'',
    acounts:[]
  },
  actionUI:'new'
};

/**
 *guarda información del usuario logueado despues de llamar el servicio de autenticación de google
 */
export const loginAsync = createAsyncThunk(
  'user/login',
  async (profile) => {
    const response = await login(profile.email);
    return {
      user: profile,
      stores: response
    };
  }
);

/**
 * 
 */
export const userListAsync = createAsyncThunk(
  'user/all',
  async () => {
    const response = await userList();
    return  response
  }
);

export const userByIdAsync = createAsyncThunk(
  'user/id',
  async (data) => {
    const response = await userById(data.id);
    return  response
  }
);

export const deleteUserAsync = createAsyncThunk(
  'user/delete',
  async (data) => {
    const response = await deleteUser(data.id, data.email);
    return  response
  }
);

export const userRolsAsync = createAsyncThunk(
  'user/rols',
  async (data) => {
    const response = await rolsByEmail(data.email);
    return  response
  }
);

export const userSaveAsync = createAsyncThunk(
  'user/save',
  async (data) => {
    const response = await saveUser(data.user, data.email);
    return  response
  }
);

export const userEditAsync = createAsyncThunk(
  'user/edit',
  async (data) => {
    const response = await editUser(data.user, data.email);
    return  response
  }
);

export const userSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    //elimina datos del usuario del store
    logOut: (state) => {
      sessionStorage.removeItem('token')
    },
    populateUser: (state) =>{
      let user = JSON.parse(sessionStorage.getItem('token'))
      state.user = user.user
      state.stores = user.stores
      state.storeActive = user.storeActive || user.stores[0]
    },
    changeStore: (state, action) => {
      state.storeActive = action.payload
      let newStore = {
        user: state.user,
        stores: state.stores,
        storeActive: action.payload
      }
      sessionStorage.setItem('token', JSON.stringify(newStore))
    },
    setUser: (state, action) => {
      state.userSelected = action.payload;
    },
    setAction: (state, action) => {
      state.actionUI = 'edit';
    },
    empyUser: (state) => {
      state.userSelected = initialState.userSelected;
      state.actionUI = initialState.actionUI;
    }

  },
  extraReducers: (builder) => {
    builder
      .addCase(loginAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(loginAsync.fulfilled, (state, action) =>  {
        state.status = 'idle';
        sessionStorage.setItem('token', JSON.stringify(action.payload))
        state.user = action.payload.user
        state.stores = action.payload.stores
        state.storeActive = action.payload.stores[0]
      })
      .addCase(loginAsync.rejected, (state, action)=>{})
      .addCase(userListAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(userListAsync.fulfilled, (state, action) =>  {
        state.status = 'idle';
        state.userList = action.payload
      })
      .addCase(userListAsync.rejected, (state, action)=>{})
      .addCase(userByIdAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(userByIdAsync.fulfilled, (state, action) =>  {
        state.status = 'idle';
      })
      .addCase(userByIdAsync.rejected, (state, action)=>{})
      .addCase(deleteUserAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteUserAsync.fulfilled, (state, action) =>  {
        state.status = 'idle';
      })
      .addCase(deleteUserAsync.rejected, (state, action)=>{})
      .addCase(userRolsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(userRolsAsync.fulfilled, (state, action) =>  {
        state.status = 'idle';
      })
      .addCase(userRolsAsync.rejected, (state, action)=>{})
      .addCase(userSaveAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(userSaveAsync.fulfilled, (state, action) =>  {
        state.status = 'idle';
      })
      .addCase(userSaveAsync.rejected, (state, action)=>{})
      .addCase(userEditAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(userEditAsync.fulfilled, (state, action) =>  {
        state.status = 'idle';
      })
      .addCase(userEditAsync.rejected, (state, action)=>{})
  },
});

export const { logOut, populateUser, changeStore, setUser, empyUser, setAction } = userSlice.actions;

export const selectUser = (state) => state.user.user;
export const storesList = (state) => state.user.stores;
export const usersList = (state) => state.user.userList;
export const storeActive = (state) => state.user.storeActive;
export const userForm = (state) => state.user.userSelected;
export const actionUI = (state) => state.user.actionUI;


export default userSlice.reducer;
