import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Table } from '../../ui/table/Table';
import { TablePaginator } from '../../ui/table/TablePaginator';
import { tableTypes } from '../../ui/table/TableShema';

import { Modal } from "../../ui/modal/Modal";
import { UserAcounts } from '../userAcounts/UserAcounts';

import { toast } from 'react-toastify';

import {
  userListAsync,
  usersList,
  userRolsAsync,
  deleteUserAsync,
  setUser,
  setAction,
  selectUser,
  empyUser
} from '../../../app/user/userSlice';

export const Users=(props) => {
  const dispatch = useDispatch();
  const users = useSelector(usersList);
  const userSelect = useSelector(selectUser);
  const [stateModal, setStateModal] = useState(false)

  const [rols, setRols] = useState([])

  const [pageSelected, setPageSelected] = useState(0)
  const [itemsPerPage, setItemsPerPage] = useState(8)
  const [filterAcounts, setFilterAcounts] = useState('')


  useEffect(() => {
    dispatch(userListAsync())
  }, []);

  /**
   * elimina una cuenta
   * @param {Object} data fila de la tabla que contiene la cuenta a eliminar
   */
  const deleteUser = (data)=>{
    dispatch(deleteUserAsync({
      id:data.idUser, 
      email:userSelect.email
    })).then((response)=>{
      if(response.error){
        toast.error(response.error.message)
      }else{
        dispatch(userListAsync())
        dispatch(empyUser())
        toast.success("Usuario eliminado con éxito")
      }
    })
  }

  /**
   * activa el modal
   * @param {*} data fila de la tabla que contiene los datos a mostrar
   */
  const viewRols = (data)=>{
    dispatch(userRolsAsync({
      email:data.email
    })).then((response)=>{
      setRols(response.payload)
    })
    setStateModal(true)
  }

  /**
   * pobla el objeto userSelected del store
   * @param {Object} data fila de la tabla que contiene el usuario
   */
  const edit = (data)=>{
    dispatch(userRolsAsync({
      email:data.email
    })).then((response)=>{
      dispatch(setUser({
        ...data,
        acounts: response.payload
      }))
      dispatch(setAction())
    })

    window.scroll({top: 0});
  }

  /**
   * modifica la columna acciones de la tabla
   * @param {Object} data fila de la tabla
   * @returns celda modificada
   */
  const templateActions = (data) =>{
    return (
      <div className='table-actions'>
        <i className="icon icon-edit-color icon-action icon-m" onClick={() => {edit(data)}}></i>
        <i className="icon icon-delete-color icon-action icon-m" onClick={() => {deleteUser(data)}}></i>
      </div>
    )
  }

  /**
   * modifica la columna cuenta de la tabla
   * @param {Object} data fila de la tabla
   * @returns celda modificada
   */
  const templateAcount = (data) =>{
    return (
      <a className="link" onClick={()=>{viewRols(data)}} >Ver Cuentas</a>
    )
  }

  /**
   * modifica la columna correo de la tabla
   * @param {Object} data fila de la tabla
   * @returns celda modificada
   */
   const templateEmail = (data) =>{
    return (
      <div className="name">
        <img></img>
        {data.email}
      </div>
    )
  }

  /**
   * se realiza copia de la data para poderla modificar
   * @returns copia de la data
   */
  const populateData = () =>{
    return users.map((user) => {
      if ((props.filter && new RegExp(props.filter, 'i').test(user.email)) || !props.filter) {
        return {
          ...user,
          acount:""
        }
      }    
    }).filter(Boolean)
  }
  const headers =[
    {name:'Correo', key:'email'},
    {name:'Cuentas', key:'acount'},
    {name:'Acciones', key:'actions'}
  ]

  const templates = [
    {
      key: 'actions',
      render: templateActions
    },
    {
      key: 'acount',
      render: templateAcount
    },
    {
      key: 'email',
      render: templateEmail
    }
  ]

  return (
    <div className="users">
      <Table
        data={populateData()} 
        headers={headers} 
        templates={templates}
        sizes = {[9,2,1]}
        type={tableTypes.LOWER_BORDER}
        itemsPerPage={itemsPerPage}
        pageSelected={pageSelected}
      >
      </Table>
      <TablePaginator
        data={populateData()}
        itemsPerPage={itemsPerPage}
        pageSelected={pageSelected}
        setPageSelected={setPageSelected}
      ></TablePaginator>
      <Modal
        open={stateModal}
        setState={setStateModal}
        search={{
          set:setFilterAcounts, 
          value:filterAcounts,
          placeholder:"Buscar Cuentas"
        }}>
        <UserAcounts type={'view'} acounts={rols} filter={filterAcounts}></UserAcounts>
      </Modal>
    </div>
  );
}
 