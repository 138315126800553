import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Box } from "../../ui/box/Box";

import { Title } from '../../ui/title/Title';
import { Back } from '../../components/back/Back';
import { ReplicateConfig } from '../../components/replicateConfig/ReplicateConfig';
import { selectUser, storeActive } from '../../../app/user/userSlice';

import { toast } from 'react-toastify';

import {
  replicateAsync
} from '../../../app/categoryTree/categoryTreeSlice';


export const ReplyCategoryTree=() => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const store = useSelector(storeActive);

  /**
   * envio de archivo
   * @param {*} destiny tienda a editar
   * @param {Boolean} boolean 
   * @returns 
   */
  const send = (destiny, boolean) => {
    return new Promise((resolve, reject) => {
      dispatch(replicateAsync({
        email:user.email, 
        origin:store.store,
        destiny: destiny.store,
        update: boolean
      })).then((response)=>{
        if(response.error){
          toast.error(response.error.message)
        }else{
          resolve()
          toast.success("Árbol de categorías replicado con éxito")
        }
      })
    })
  }

  return (
    <div className="category-tree">
      <Back></Back>
      <Title title="Replicar árbol de categorías"></Title>
      <Box>
        <ReplicateConfig update send={send}></ReplicateConfig>
      </Box>
    </div>
  );
}
