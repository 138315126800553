import React, { useState } from 'react';
import { InputFile } from '../../ui/inputFile/InputFile';

import { Modal } from "../../ui/modal/Modal";
import { modalSizes } from '../../ui/modal/ModalShema';
import { Comfirm } from '../confirm/Confirm';



export const LoadAndSendFile=(props) => {
  const [file, setFile] = useState('') 

  const [stateModalConfirm, setStateModalConfirm] = useState(false)

  /**
   * validación de llenado de los campos necesarios del formulario
   * @returns booleano con el resultado
   */
  const disabled = () => {
    if(file) return false
    return true
  }
  
  /**
   * envio de formulario
   */
  const load = (confirm) => {
    setStateModalConfirm(false)
    if(!confirm) return
    props.send(file)
  }

  return (
    <form className="load-file">
      <div className="form-row">
        {
          props.formats &&
          <p>Ya cuentas con el formato para cargar el archivo? si no 
            {
              props.formats.map((format, index) =>{
                return (
                  <i key={index}> haz <a className="link" href={format.url}>click aquí</a> para descargar el formato
                    {format.type ? ` de ${format.type}` : ''}
                    {index < props.formats.length -1 ? ' o ': ''}
                  </i>
                )
              } )
            }
          </p>
        }
      </div>
      <div className="form-actions-row">
        <InputFile value={file} setValue={setFile}></InputFile>
        <button className="secondary-btn" disabled={disabled()} type='button' onClick={()=>setStateModalConfirm(true)}>Cargar</button>
      </div>

      <Modal
        open={stateModalConfirm}
        setState={setStateModalConfirm}
        size={modalSizes.EXTRA_SHORT}>
        <Comfirm send={load} action={props.actions}></Comfirm>
      </Modal>
    </form>
  );
}
 