import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import {storeActive} from '../../../app/user/userSlice';


export const NavItem=(props) => {
  
  let history = useHistory();

  const activeStore = useSelector(storeActive);

  /**
   * redirige a la página seleccionada
   * @param {*} path url a redirigir
   */
  const goTo = (path) => {
    props.setItem(path)
    history.push(`/${path}`);
  }

  return (
    <li>
      <div className={`nav-item ${ props.activeGroup  ? "active" : ""}`} onClick={()=>{props.setGroup(props.name)}}>
        <div className="nav-icon">
          <i className={"icon icon-s icon-"+props.icon}></i>
        </div>
        {props.name}
      </div>
      {
        props.items.length > 0 && props.activeGroup &&
        <ul>
          {
             props.items.map((item, index)=>{
              if(
                activeStore.rols && activeStore.rols.find(permission => permission.id === item.backId) &&
                ( new RegExp(props.search, 'i').test(item.name) || 
                new RegExp(props.search, 'i').test(props.name)) 
              ){
                return( 
                  <li 
                    className={`${props.activeLink === item.path ? "active" : ""}`}
                    key={index} onClick={()=>{goTo(item.path)}}>
                      {item.name}
                  </li>
                )
              }
            })
          }
        </ul>
      }
    </li>   
  );
}
