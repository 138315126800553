import React, { useState } from 'react'
import { Box } from '../../ui/box/Box';
import { Modal } from '../../ui/modal/Modal';
import { modalSizes } from '../../ui/modal/ModalShema';
import { ConfirmPrice } from '../confirmPrice/ConfirmPrice';

import { DownLoadFormat } from '../downLoadFormat/DownLoadFormat';

export const TabsPrices = (props) => {

  const [toggleState, setToggleState] = useState(1)
  const [stateModalConfirm, setStateModalConfirm] = useState(false)

  /**
   * activa alguno de los tabs desactivando el resto 
   * @param {number} index posición del tab a activar  
   */
  const toggleTab = (index) => {
    setToggleState(index);
  };
  

  return (
    <>
      <div>
        {toggleState===1 ? (
          <Box>
            <DownLoadFormat formats={props.formats[0]}></DownLoadFormat>
          </Box>
        ) : (
          <Box>
            <DownLoadFormat formats={props.formats[1]}></DownLoadFormat>
          </Box>
        )}
      </div>
    

      <div className="tabs-container">
        <div className="tabs-bloc-tabs">
          <button
            className={toggleState === 1 ? "tabs-tabs tabs-active-tabs" : "tabs-tabs"}
            onClick={() => toggleTab(1)}
          >
            Tabla de precios Base
          </button>

          <button
            className={toggleState === 2 ? "tabs-tabs tabs-active-tabs" : "tabs-tabs"}
            onClick={() => toggleTab(2)}
          >
            Tabla de precios programados
          </button>
        </div>
        <div className="tabs-content-tabs">
          <div
            className={toggleState === 1 ? "tabs-content  tabs-active-content" : "tabs-content"}
          >
            <div className="tabs-container-download" onClick={()=>{props.downLoad(false)}}>
              <i className="icon icon-download-color"></i>
              <p> Descargar precios base </p>
            </div>
            
            <div className="tabs-container-info">
              <div className="tabs-content-info-title">
                <label>Contenido necesario para importar tablas de precios base:</label>
              </div>
              <div className="tabs-content-info">
                <label>✓ REF ID</label>
                <label>✓ List price</label>
                <label>✓ Date from</label>   
              </div>
            </div>

            <div className="tabs-container-imp">
              <button className="secondary-btn" type="button" onClick={()=>{setStateModalConfirm(true)}}>Importar tabla</button>
              <label>El archivo debe pesar máximo 1 Mb.</label>
            </div>
    
          </div>

          <div
            className={toggleState === 2 ? "tabs-content  tabs-active-content" : "tabs-content"}
          >
            <div className="tabs-container-download" onClick={()=>{props.downLoad(true)}}>
              <i className="icon icon-download-color"></i>
              <p> Descargar precios Programados </p>
            </div>
          
            <div className="tabs-container-info">
              <div className="tabs-content-info-title">
                <label>Contenido necesario para importar tablas de precios programados:</label>
              </div>
              <div className="tabs-content-info">
                <label>✓ REF ID</label>
                <label>✓ List price</label>
                <label>✓ Date from</label>
                <label>✓ SKU ID</label>
                <label>✓ Min quantity</label>
                <label>✓ Date to</label>
                <label>✓ Trade policy</label>
                <label>✓ Price now</label>
              </div>
            </div>

            <div className="tabs-container-imp">
              <button className="secondary-btn" type="button" onClick={()=>{setStateModalConfirm(true)}}>Importar tabla</button>
              <label>El archivo debe pesar máximo 1 Mb.</label>
            </div>
          </div>
        </div>

        <Modal
          open={stateModalConfirm}
          setState={setStateModalConfirm}
          size={modalSizes.SHORT}
        >
         
          <ConfirmPrice tab={toggleState} />
        
        </Modal>
      </div>
    </>
  )
}
