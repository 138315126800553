import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RadioOrCheck } from '../../ui/radioOrCheck/RadioOrCheck';
import { inputTypes } from '../../ui/radioOrCheck/RadioOrCheckShema';
import { Select } from '../../ui/select/Select';
import {storesList} from '../../../app/user/userSlice';

import { Modal } from "../../ui/modal/Modal";
import { modalSizes } from '../../ui/modal/ModalShema';
import { Comfirm } from '../../components/confirm/Confirm';
import {actions} from'../../components/confirm/ConfirmShema'

export const ReplicateConfig=(props) => {
  const stores = useSelector(storesList);
  const [storeList, setStoreList] = useState([])
  const [storeReplicate, setStoreReplicate] = useState()
  const [boolean, setBoolean] = useState('')

  const [stateModalConfirm, setStateModalConfirm] = useState(false)
  /**
   * llena la lista de cuentas del usuario
   */
  useEffect(()=>{
    let list = stores.map((store)=>{
      return{
        ...store,
        label : store.store,
        value : store.store
      }
    })

    list.sort(function (a, b) {
      if (a.label > b.label) {
        return 1;
      }
      if (a.label < b.label) {
        return -1;
      }
      // a must be equal to b
      return 0;
    });
    setStoreList(list)
  },[stores])


  /**
   * cambio de estado del selector
   * @param {*} value nuevo valor
   */
  const action = (value) =>{
    setStoreReplicate(value);
  }

  /**
   * validación de llenado de los campos necesarios del formulario
   * @returns booleano con el resultado
   */
  const disabled = () => {
    if(props.update && (storeReplicate && boolean)) {
      return false
    }else if(!props.update && storeReplicate) return false
    
    return true
  }

  /**
   * envio del formulario
   */
  const replicate = (confirm) => {
    setStateModalConfirm(false)
    if(!confirm) return
    if(props.update) {
      props.send(storeReplicate, boolean === 'SI' ? true : false).then(()=>{
        setStoreReplicate('')
      })
    }else {
      props.send(storeReplicate).then(()=>{
        setStoreReplicate('')
      })
    }
  }
  
  return (
    <form className="load-category-tree">
      <div className="form-row">
        <label>Tienda destino:</label>
        <Select 
          options={storeList}
          placeholder="seleccione una opción"
          selected={action}
          selectValue={storeReplicate ? storeReplicate.label : ''}
          filter
        ></Select>
      </div>
      {
        props.update ?
        (
          <div className={`form-actions-row actions--inline `}>
            <label>Actualizar:</label>
            <div className="input-and-action">
              <div className="radios">
                <RadioOrCheck label="SI" type={inputTypes.RADIO} name="update" select={setBoolean}></RadioOrCheck>
                <RadioOrCheck label="NO" type={inputTypes.RADIO} name="update" select={setBoolean}></RadioOrCheck>
              </div>
              <button className="secondary-btn" disabled={disabled()} type='button' onClick={()=>{setStateModalConfirm(true)}}>Enviar</button>
            </div>
          </div>
        ) : (
          <div className={`form-actions-row`}>
            <button className="secondary-btn" disabled={disabled()} type='button' onClick={()=>{setStateModalConfirm(true)}}>Enviar</button>
          </div>
        )
      }
      <Modal
        open={stateModalConfirm}
        setState={setStateModalConfirm}
        size={modalSizes.EXTRA_SHORT}>
        <Comfirm send={replicate} action={actions.COPY}></Comfirm>
      </Modal>
    </form>
  );
}
 