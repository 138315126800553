import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Select } from '../../ui/select/Select';
import { Module } from '../../ui/module/Module';
import { shema as navShema } from '../nav/NavShema';

import {
  acountsList,
  acountListAsync
} from '../../../app/acount/acountSlice';


export const UserAcounts=(props) => {

  /**
   * llenado de el listado de permisos de un usuario por tiendas
   * se tiene antes del seteo de las variables porque es usado para el estado inicial de las variables
   * @returns arreglo por tienda con el listadocde permisos
   */
  const populateAcounts = () => {
    let acountList = []
    if (props.acounts) {
      let newAcounts = JSON.parse(JSON.stringify(props.acounts))
      for (let i = 0; i < newAcounts.length; i++) {
        let newShema = JSON.parse(JSON.stringify(shema))
        const acount = newAcounts[i];
        acountList.push(acount)
        let rolList = newShema
        
        for (const [j, rol] of rolList.entries()) {
          if(acount.rols.find(r => r.id === rol.backId || (r.backId === rol.backId && r.state === true))){
            rolList[j].state = true
          }
        }
        acountList[i].rols = rolList
      }
    }
    return acountList
  }
  

  const dispatch = useDispatch();
  const [shema, setShema] = useState([])
  const [acounts, setAcounts] = useState(populateAcounts())
  const listAcounts = useSelector(acountsList);

  useEffect(() => {
    setShema(populateShema())
    dispatch(acountListAsync())
  }, []);

  useEffect(() => {
    setAcounts(populateAcounts())
    if( (!props.acounts || acounts.length === 0) && shema.length > 0){
      addAcount()
    }
    
  }, [shema]);

  useEffect(() => {
    setAcounts(populateAcounts())
  }, [props.acounts]);

  /**
   * agrega una cuenta al arreglo junto con el listado de permisos
   */
  const addAcount = () => {
    let newAcounts = JSON.parse(JSON.stringify(acounts))
    newAcounts.push({
      store: '',
      rols: shema
    })
    setAcounts(newAcounts)
    if(props.updateAcounts)props.updateAcounts(newAcounts)
  }
  
   /**
   * elimina una cuenta al arreglo junto con el listado de permisos
   */
  const deleteAcount = (index) => {
    let newAcounts = JSON.parse(JSON.stringify(acounts))
    newAcounts.splice(index, 1);
    props.updateAcounts(newAcounts)
  }

  /**
   * pobla un iten de permisos para una cuenta 
   * @param {*} item permiso a agregar
   * @param {*} icon icono del elemento padre
   * @param {*} section id de la seccion
   * @param {*} sectionName nombre de la seccion
   * @returns  objeto ´con el permiso
   */
  const itemMap = (item, icon, section,sectionName) => {
    return {
      ...item,
      state: false,
      icon: icon,
      section: section,
      sectionName: sectionName
    }
  }

  /**
   * llena el listado de permisos de una tienda
   * @returns afrreglo con todos los permisos
   */
  const populateShema = () => {
    let newShema = JSON.parse(JSON.stringify(navShema))
    let integrado = newShema.reduce(function(current,next, index) {
      if(index === 1){
        current.items = current.items.map((item) => {
          return itemMap(item, current.icon, current.section, current.name)
        })
      }
      next.items = next.items.map((item) => {
        return itemMap(item, next.icon, next.section, next.name)
      })
      current.items = current.items.concat(next.items)
      return current;
    });
    return integrado.items
  }

  /**
   * cambia la tienda a la que pertenece un listado de permisos
   * @param {*} value nueva tienda
   * @param {*} index pososion en el arreglo  de permisos que contiene los permisos a ser cambiados de tienda
   */
  const changeStore = (value, index) =>{
    let newAcounts = JSON.parse(JSON.stringify(acounts))
    newAcounts[index].store = value.store
    props.updateAcounts(newAcounts)
  }

  /**
   * activa o desactiva un permiso para una tienda
   * @param {*} parentIndex pososion en el arreglo de permisos que contiene el permiso a cambiar
   * @param {*} index posision del peermiso dentro de su parentIndex
   */
  const togglStateModule = (parentIndex, index) =>{
    if (props.type !=='view'){
      let newAcounts = JSON.parse(JSON.stringify(acounts))
      newAcounts[parentIndex].rols[index].state = !newAcounts[parentIndex].rols[index].state
      setAcounts(newAcounts)
      props.updateAcounts(newAcounts)
    }
  }

  /**
   * validación de llenado de los campos necesarios del formulario
   * @returns booleano con el resultado
   */
  const disabledAddAcount = () => {
    if (acounts.length > 0 && acounts[acounts.length-1].rols.find(rol => rol.state === true) && acounts[acounts.length-1].store ) return false
    return true
  }

  /**
   * valida que el ítem de la lista de cuentas no se haya seleccionado previamente y de ser asi deshabilita la opción
   * @returns lista de cuentas con el estado disabled 
   */
  const disabledSelectedAcount = () =>{
    let newListAcounts = JSON.parse(JSON.stringify(listAcounts))
    newListAcounts = newListAcounts.map(listAcount =>{
      if (acounts.some(acount => acount.store === listAcount.store)) {
        return {
          ...listAcount,
          disabled: true
        }
      }else{
        return {
          ...listAcount
        }
      }
    })

    return newListAcounts
  }

  return (
    <div className='form userAcounts'>
      {
        acounts.map((acount, parentIndex) =>{
          if( !props.filter || acount.store.includes(props.filter) ){

            return  <div key={parentIndex}>
              {
                props.type =='view' &&  
                <div className='form-row'>
                  <h3 className='acount-title'><label>{`${parentIndex+1}. `}</label>{`${acount.store}`}</h3>
                </div>
              }
              
              {
                props.type !=='view' &&
                <div className="form-row acount-select-row">
                  <h3 className='acount-title'><label>{`${parentIndex+1}. `}</label></h3>
                  <Select 
                    options={disabledSelectedAcount()}
                    placeholder="seleccione una opción"
                    selected={(data) =>{changeStore(data,parentIndex)}}
                    selectValue={acount.store}
                    filter
                  ></Select>
                  {
                    acounts.length !== 1 &&
                    <div onClick={() => {deleteAcount(parentIndex)}} className="acount-delete">
                      <i className="icon icon-delete-color icon-action icon-m"></i>
                      <p>Eliminar cuenta</p>
                    </div>
                  }
                </div>
              }
              <div className="form-row">
                <div className={`modules accounts-module ${props.type =='view' ? 'disabled': ''}`}>

                  <h4>Nuevo</h4>
                  <div className="accounts-module--container">
                    {
                      acount.rols.map((rol, index) =>{
                        if(rol.section == 1){
                          return <Module key={index} {...rol} icon={'check'} large={'l'} set={()=>{togglStateModule(parentIndex, index)}}></Module>
                        }
                      })
                    }
                  </div>

                  <h4>Consultar y modificar</h4>
                  <div className="accounts-module--container">
                    {
                      acount.rols.map((rol, index) =>{
                        if(rol.section == 2){
                          return <Module key={index} {...rol} icon={'check'} large={'l'} set={()=>{togglStateModule(parentIndex, index)}}></Module>
                        }
                      })
                    }
                  </div>

                  <h4>Crear catálogo</h4>
                  <div className="accounts-module--container">
                    {
                      acount.rols.map((rol, index) =>{
                        if(rol.section == 3){
                          return <Module key={index} {...rol} icon={'check'} large={'l'} set={()=>{togglStateModule(parentIndex, index)}}></Module>
                        }
                      })
                    }
                  </div>

                  <h4>Replicar</h4>
                  <div className="accounts-module--container">
                    {
                      acount.rols.map((rol, index) =>{
                        if(rol.section == 4){
                          return <Module key={index} {...rol} icon={'check'} large={'l'} set={()=>{togglStateModule(parentIndex, index)}}></Module>
                        }
                      })
                    }
                  </div>

                  <h4>Inventario</h4>
                  <div className="accounts-module--container">      
                    {
                      acount.rols.map((rol, index) =>{
                        if(rol.section == 6){
                          return <Module key={index} {...rol} icon={'check'} large={'l'} set={()=>{togglStateModule(parentIndex, index)}}></Module>
                        }
                      })
                    }
                  </div>
                  
                  <h4>Precios</h4>
                  <div className="accounts-module--container">     
                    {
                      acount.rols.map((rol, index) =>{
                        if(rol.section == 7){
                          return <Module key={index} {...rol} icon={'check'} large={'l'} set={()=>{togglStateModule(parentIndex, index)}}></Module>
                        }
                      })
                    }
                  </div>

                  <h4>Administrar</h4>
                  <div className="accounts-module--container">
                    {
                      acount.rols.map((rol, index) =>{
                        if(rol.section == 5){
                          return <Module key={index} {...rol} icon={'check'} large={'l'} set={()=>{togglStateModule(parentIndex, index)}}></Module>
                        }
                      })
                    }
                  </div>
                </div>
              </div>
            </div>
          }
        })
      }
      {
        props.type !=='view' &&
        <div className="form-row">
          <button type="button" className="primary-btn" onClick={addAcount} disabled={disabledAddAcount()}>Nueva Cuenta</button>
        </div>
      }
      
    </div>
  );
}
 