import React from 'react';


export const Box=(props) => {

  return (
    <div className="box">
      {props.children}
    </div>
  );
}