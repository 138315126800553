import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Box } from "../../ui/box/Box";
import { Title } from '../../ui/title/Title';
import { LoadFile } from '../../components/loadFile/LoadFile';
import { FilesLoaded } from '../../components/filesLoaded/FilesLoaded';
import { Back } from '../../components/back/Back';
import { LoadCategoryTree } from '../../components/loadCategoryTree/LoadCategoryTree';
import { selectUser, storeActive } from '../../../app/user/userSlice';

import { toast } from 'react-toastify';

import {
  createAsync
} from '../../../app/productBrand/productBrandSlice';


export const NewProductBrands=() => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const store = useSelector(storeActive);

  const formats =[
    {url:'https://clientes.titamedia.com/master-c/formatos/Nuevo/Nuevas-marcas-de-producto.xlsx'}
  ]

  /**
   * envio de archivo
   * @param {*} file archivo a enviar
   * @returns 
   */
  const send = (file) => {
    return new Promise((resolve, reject) => {
      dispatch(createAsync({
        email:user.email, 
        storeName:store.store, 
        path: file.path
      })).then((response)=>{
        if(response.error){
          toast.error(response.error.message)
        }else{
          resolve()
          toast.success("Marcas de producto cargado con éxito")
        }
      })
    })
  }

  return (
    <div className="category-tree">
      <Back></Back>
      <Title title="Nuevas marcas de producto"></Title>
      <Box>
        <LoadFile category={'NMP_'} type={'uploads'} formats={formats}></LoadFile>
      </Box>
      <FilesLoaded type={'uploads'} category={'NMP_'}></FilesLoaded>
      <Box>
        <LoadCategoryTree send={send} category={'NMP_'}></LoadCategoryTree>
      </Box>
    </div>
  );
}
 