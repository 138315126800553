import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { changeCollection, scheduledCollection, downLoadCollection } from './collectionsAPI';


const initialState = {
  status: 'idle',
  selectedStoreDownload:''
};


/**
 * 
 */
export const changeAsync = createAsyncThunk(
  'collections/create',
  async (data) => {
    const response = await changeCollection(data.email, data.storeName, data.path, data.update);
    return  response
  }
);

export const scheduledAsync = createAsyncThunk(
  'collections/scheduled',
  async (data) => {
    const response = await scheduledCollection(data.email, data.storeName, data.path, data.update, data.date);
    return response
  }
);

export const downLoadAsync = createAsyncThunk(
  'collections/downLoad',
  async (data) => {
    const response = await downLoadCollection(data.email, data.storeName);
    console.log(">>>>>>>>>>>>>> response",response);
    //console.log(">>>>>>>>>>>>>> window.atob(encodedData)",window.atob(response));
    
    const url = window.URL
        .createObjectURL(new Blob([ response]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `descarga-colecciones.xlsx`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    return 'OK'
  }
);


export const collectionsSlice = createSlice({
  name: 'acount',
  initialState,
  reducers: {
    setStore: (state, action) => {
      state.selectedStoreDownload = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(changeAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(changeAsync.fulfilled, (state, action) =>  {
        state.status = 'idle';
      })
      .addCase(changeAsync.rejected, (state, action)=>{})
      .addCase(scheduledAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(scheduledAsync.fulfilled, (state, action) =>  {
        state.status = 'idle';
      })
      .addCase(scheduledAsync.rejected, (state, action)=>{})
      .addCase(downLoadAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(downLoadAsync.fulfilled, (state, action) =>  {
        state.status = 'idle';
      })
      .addCase(downLoadAsync.rejected, (state, action)=>{})
  },
});


export const selectedStoreDownload = (state) => state.collections.selectedStoreDownload;

export default collectionsSlice.reducer;
