export const shema = [
  {
    icon:"new",
    name:"Nuevo",
    section: 1,
    items:[
      {
        name:"Nuevo árbol de categorías",
        path:"category-tree",
        backId: 1,
        backName: "N_Ar_cat"
      },
      {
        name:"Nuevos atributos y productos SKU",
        path:"new-attr-and-sku",
        backId: 2,
        backName: "N_At_prod_sku"
      },
      {
        name:"Nuevas marcas de producto",
        path:"new-product-brands",
        backId: 3,
        backName: "N_M_prod"
      },
    ]
  },
  {
    icon:"edit",
    name:"Modificar y Descargar",
    section: 2,
    items:[
      {
        name:"Descargar árbol de categorías",
        path:"download-category-tree",
        backId: 4,
        backName: "D_Ar_cat"
      },
      {
        name:"Descargar atributos de producto y SKU (Árbol de categorías)",
        path:"download-attr-and-sku",
        backId: 5,
        backName: "D_At_prod_sku"
      },
      {
        name:"Descargar marcas",
        path:"download-brands",
        backId: 7,
        backName: "D_M"
      },
      {
        name:"Modificar árbol de categorías - Metadatos",
        path:"change-category-tree",
        backId: 6,
        backName: "M_Ar_cat"
      },
      {
        name:"Modificar y descargar colecciones",
        path:"change-collections",
        backId: 21,
        backName: "M_co"
      },
      {
        name:"Modificar valores de productos y skus",
        path:"change-product-and-sku",
        backId: 22,
        backName: "M_in"
      },
      {
        name:"Eliminar Imágenes de SKU",
        path:"delete-img",
        backId: 17,
        backName: "B_S_M"
      }
    ]
  },
  {
    icon:"create",
    name:"Crear catálogo de Productos y SKUS",
    section: 3,
    items:[
      {
        name:"Productos y SKUS",
        path:"create-product-and-sku",
        backId: 8,
        backName: "C_prod_sku"
      },
      {
        name:"Descargar atributos de producto",
        path:"download-product-attr",
        backId: 9,
        backName: "D_At_pro"
      },
      {
        name:"Cargar especificaciones de producto",
        path:"load-product-specs",
        backId: 10,
        backName: "Car_esp_prod"
      },
      {
        name:"Descargar atributos SKU",
        path:"download-sku-attr",
        backId: 11,
        backName: "D_At_sku"
      },
      {
        name:"Cargar especificaciones de SKU",
        path:"load-sku-specs",
        backId: 12,
        backName: "Car_Esp_sku"
      },
      {
        name:"Importar imágenes",
        path:"load-img",
        backId: 13,
        backName: "I_sku"
      },
    ]
  },
  {
    icon:"inventory",
    name:"Inventario",
    section: 6,
    items:[
      {
        name:"Actualización de inventario",
        path:"change-inventory",
        backId: 24,
        backName: "Inv"
      }
    ]
  },
  {
    icon:"price",
    name:"Precios",
    section: 7,
    items:[
      {
        name:"Cargue de Precios",
        path:"load-price",
        backId: 25,
        backName: "Pr"
      },
      {
        name:"Eliminar Precios de SKU",
        path:"delete-price",
        backId: 26,
        backName: "delete_prices"
      }
    ]
  },
  {
    icon:"replicate",
    name:"Replicar",
    section: 4,
    items:[
      {
        name:"Replicar árbol de categorías",
        path:"reply-category-tree",
        backId: 14,
        backName: "R_Ar_cat"
      },
      {
        name:"Replicar Marcas",
        path:"reply-brands",
        backId: 15,
        backName: "R_M"
      },
    ]
  },
  {
    icon:"config",
    name:"Gestión",
    section: 5,
    items:[
      {
        name:"Usuarios",
        path:"new-user",
        backId: 18,
        backName: "c_u"
      },
      {
        name:"Cuentas",
        path:"new-account",
        backId: 16,
        backName: "c_ti"
      },
    ]
  }
]
  

