import React, { useState } from 'react';


export const Accordion=(props) => {

  const [hideAccordion, setHideAccordion] = useState(false)

  /**
   * llamado a la accion enviada por props
   * @param {*} e evento click
   */
  const action = (e) => {
    e.stopPropagation()
    props.button.action('ejecutado')
  }

  return (
    <div className="accordion">
      <div className="accordion-header" onClick={()=>{setHideAccordion(!hideAccordion)}}>
        <i className={`icon icon-s icon-arrow-${hideAccordion ? 'down': 'up'}`}></i>
        <h4>{props.title}</h4>
        {
          props.setSearch &&
          <div className="search-input">
            <i className="icon icon-s icon-search"></i>
            <input className='border' placeholder={props.searchPlaceholder} value={props.search} onChange={e => props.setSearch(e.target.value)} onClick={(e)=>{e.stopPropagation()}}/>
          </div>
        }
        {
          props.button &&
          <button onClick={(e)=>{action(e)}}>
            {props.button.label}
          </button>
        }
        
      </div>
        {
          !hideAccordion &&
          <div className="accordion-content">
            {props.children}
          </div>
        }
    </div>
  );
}