import { axiosInstance } from "../../serviceConf";

export function acountList() {
  let url = `api/VtexKey/all`
  return new Promise((resolve, reject) =>
    axiosInstance.get(url,{})
    .then((response) => {
      resolve(response.data)
    }).catch((err)=>{
      reject(err)
    })
  )
}

export function acountNew(email, storeName, apiKey, apiToken) {
  let url = `api/VtexKey/create`
  let params = {
    email: email,
    store: storeName,
    apiKey: apiKey,
    apiToken: apiToken,
    enviar:'Enviar'
  }
  return new Promise((resolve, reject) =>
    axiosInstance.get(url,{ params })
    .then((response) => {
      resolve(response.data)
    }).catch((err)=>{
      reject(err)
    })
  )
}

export function acountDelete(storeName, email){
  let url = `api/VtexKey/delete/${storeName}/${email}`

  return new Promise((resolve, reject) =>
    axiosInstance.get(url,{})
    .then((response) => {
      resolve(response.data)
    }).catch((err)=>{
      reject(err)
    })
  )
}