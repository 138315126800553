import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Table } from '../../ui/table/Table';
import { tableTypes } from '../../ui/table/TableShema';
import { TablePaginator } from '../../ui/table/TablePaginator';

import { selectUser } from '../../../app/user/userSlice';

import { toast } from 'react-toastify';

import {
  acountsList,
  acountListAsync,
  setAcount,
  acountDeleteAsync,
  empyAcount
} from '../../../app/acount/acountSlice';


export const Accounts=() => {

  const dispatch = useDispatch();
  const acounts = useSelector(acountsList);
  const user = useSelector(selectUser);

  const [pageSelected, setPageSelected] = useState(0)
  const [itemsPerPage, setItemsPerPage] = useState(8)

  const [filterAccounts, setFilterAccounts] = useState('')

  useEffect(() => {
    dispatch(acountListAsync())
  }, []);

  /**
   * pobla el objeto acountSelected del store
   * @param {Object} data fila de la tabla que contiene la cuenta a editar
   */
  const edit = (data)=>{
    dispatch(setAcount({
      ...data,
      accessToken: data.apiToken
    }))
    window.scroll({top: 0});
  }

  /**
   * elimina una cuenta
   * @param {Object} data fila de la tabla que contiene la cuenta a eliminar
   */
  const deleteAcount = (data)=>{
    dispatch(acountDeleteAsync({
      storeName:data.store, 
      email:user.email
    })).then((response)=>{
      if(response.error){
        toast.error(response.error.message)
      }else{
        dispatch(acountListAsync())
        dispatch(empyAcount())
        toast.success("Tienda eliminada con éxito")
      }
    })
  }

  /**
   * modifica la columna acciones de la tabla
   * @param {Object} data fila de la tabla
   * @returns celda modificada
   */
  const templateActions = (data) =>{
    return (
      <div>
        <i className="icon icon-edit-color icon-action icon-m" onClick={() => {edit(data)}}></i>
        <i className="icon icon-delete-color icon-action icon-m" onClick={() => {deleteAcount(data)}}></i>
      </div>
    )
  }

  /**
   * copia un texto al portapapeles
   * @param {string} text texto a copiar
   */
  const copy = (text) => {
    navigator.clipboard.writeText(text)
  }

  /**
   * modifica la columna apiKey de la tabla
   * @param {Object} data fila de la tabla
   * @returns celda modificada
   */
  const templateApiKey = (data) =>{
    return (
      <div className="copy">
        <p>{data.apiKey}</p>
        <i className={`icon icon-s icon-action icon-copy`} onClick={()=>{ copy(data.apiKey) }}></i>
      </div>
    )
  }
  /**
   * modifica la columna apiToken de la tabla
   * @param {Object} data fila de la tabla
   * @returns celda modificada
   */
   const templateAccesToken = (data) =>{
    return (
      <div className="copy">
        <p>{data.apiToken}</p>
        <i className={`icon icon-s icon-action icon-copy`} onClick={()=>{ copy(data.apiToken) }}></i>
      </div>
    )
  }

  /**
   * modifica la columna store de la tabla
   * @param {Object} data fila de la tabla
   * @returns celda modificada
   */
   const templateStore = (data) =>{
    return (
      <div className="name">
        <img></img>
        {data.store}
      </div>
    )
  }

  const headers =[
    {name:'store', key:'store'},
    {name:'Accestoken', key:'apiToken'},
    {name:'Apikey', key:'apiKey'},
    {name:'Acciones', key:'actions'}
  ]

  const templates = [
    {
      key: 'actions',
      render: templateActions
    },
    {
      key: 'apiKey',
      render: templateApiKey
    },
    {
      key: 'apiToken',
      render: templateAccesToken
    },
    {
      key: 'store',
      render: templateStore
    }
  ]

  /**
   * se realiza copia de la data para poderla modificar
   * @returns copia de la data
   */
  const populateData = () =>{
    return acounts.map((acount) => {
      if ((filterAccounts && new RegExp(filterAccounts, 'i').test(acount.store)) || !filterAccounts) {
        return {
          ...acount
        }
      }    
    }).filter(Boolean)
  }

  return (
    <div className="accounts">
      <div className="search-input">
        <i className="icon icon-s icon-search"></i>
        <input className='border' placeholder="Buscar" value={filterAccounts} onChange={e => setFilterAccounts(e.target.value)}/>
      </div>
      <Table
        data={populateData()} 
        headers={headers} 
        templates={templates}
        sizes = {[3,3,3,1]}
        type={tableTypes.LOWER_BORDER}
        itemsPerPage={itemsPerPage}
        pageSelected={pageSelected}
      >
      </Table>
      <TablePaginator
        data={populateData()}
        itemsPerPage={itemsPerPage}
        pageSelected={pageSelected}
        setPageSelected={setPageSelected}
      ></TablePaginator>
    </div>
  );
}
 