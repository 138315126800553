import React, { useState, useEffect } from 'react';
import {togleBodyFrozen} from '../../../utilities'


export const Modal=(props) => {

  /**
   * muestra u oculta el modal 
   * @param {*} e evento click
   */
  const toggleModal = (e) => {
    props.setState(!props.open)
    if(props.search.set) props.search.set()
  }

  useEffect(() => {
    togleBodyFrozen(props.open)
  }, [props.open]);


  /**
   * envía la acción de cancel del componente padre y oculta el modal
   */
  const cancel = () => {
    if (props.footer.cancel.action) props.footer.cancel.action()
    props.setState(!props.open)
  }

  /**
   * envía la acción de send del componente padre
   */
  const send = () => {
    if (props.footer.send.action) props.footer.send.action()
  }

  return (
    <>
      {props.open &&
        <div className={`modal ${props.size || ''}`} onClick={(e)=>{toggleModal(e)}}>
          <div className='modal-content' onClick={(e)=>{e.stopPropagation()}}>
            <div className='modal-content--header'>
            {
              props.search && props.search.set &&
              <div className="search-input">
                <i className="icon icon-s icon-search"></i>
                <input className='border' placeholder={props.search.placeholder} value={props.search.value} onChange={e => props.search.set(e.target.value)} onClick={(e)=>{e.stopPropagation()}}/>
              </div>
            }
              <i className='icon icon-action icon-close icon-s' onClick={(e)=>{toggleModal(e)}}></i>
            </div>

            
            <div className='modal-content--body'>
              {props.children}
            </div>
            <div className='modal-content--footer'>
              {
                props.footer && props.footer.cancel.text &&
                <button className="link-btn" disabled={props.footer.cancel.disabled} type="button" onClick={()=>{cancel()}}>
                  {props.footer.cancel.text}
                </button>
              }
              {
                props.footer && props.footer.send.text &&
                <button className="secondary-btn" disabled={props.footer.send.disabled} type="button" onClick={()=>{send()}}>
                  {props.footer.send.text}
                </button>
              }
            </div> 
          </div>
        </div>
      }
    </>
  );
}