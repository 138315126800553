import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Box } from "../../ui/box/Box";

import { Title } from '../../ui/title/Title';
import { LoadFile } from '../../components/loadFile/LoadFile';
import { FilesLoaded } from '../../components/filesLoaded/FilesLoaded';
import { Back } from '../../components/back/Back';
import { LoadCategoryTree } from '../../components/loadCategoryTree/LoadCategoryTree';
import { inputTypes } from '../../components/loadCategoryTree/LoadCategoryTreeShema';
import { selectUser, storeActive } from '../../../app/user/userSlice';

import { toast } from 'react-toastify';

import { Modal } from "../../ui/modal/Modal";
import { FilesScheduled } from '../../components/filesScheduled/FilesScheduled';

import {listScheduledAsync } from '../../../app/files/filesSlice';

import {
  createAsync,
  scheduledAsync
} from '../../../app/categoryTree/categoryTreeSlice';




export const CategoryTree=() => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const store = useSelector(storeActive);
  const [stateModal, setStateModal] = useState(false)

  const formats =[
    {url:'https://clientes.titamedia.com/master-c/formatos/Nuevo/Nuevo-arbol-de-categorias.xlsx'}
  ]

  /**
   * envio de archivo
   * @param {*} file archivo a enviar
   * @returns 
   */
  const send = (file) => {
    return new Promise((resolve, reject) => {
      dispatch(createAsync({
        email:user.email, 
        storeName:store.store, 
        path: file.path,
        update: true
      })).then((response)=>{
        if(response.error){
          toast.error(response.error.message)
        }else{
          resolve()
          toast.success("Arbol de categorías cargado con éxito")
        }
      })
    })
  }

  /**
   * porgramación de envio de archivo
   * @param {*} file archivo a enviar
   * @param {Date} date 
   * @returns 
   */
   const scheduled = (file, boolean, date) => {
       return new Promise((resolve, reject) => {
      dispatch(scheduledAsync({
        email:user.email, 
        storeName:store.store, 
        path: file.path,
        update: boolean,
        date: date
      })).then((response)=>{
        if(response.payload){
          toast.error(response.payload.message)
        }else{
          dispatch(listScheduledAsync({category: 'catalogo'}))
          setStateModal(false)
          toast.success("Arbol de categorías programado con éxito")
        }
      })
    })
  }

  return (
    <div className="category-tree">
      <Back></Back>
      <Title title="Nuevo árbol de categorías"></Title>
      
      <Box>
        <LoadFile category={'NAC_'} type={'uploads'} formats={formats}></LoadFile>
      </Box>
      <FilesLoaded type={'uploads'} category={'NAC_'}></FilesLoaded>
      <Box>
        <LoadCategoryTree type={ inputTypes.UPDATE} send={send}></LoadCategoryTree>
      </Box>
      <p> 
        Si deseas programar el cargue de información, haz <a className="link" onClick={()=>{setStateModal(true)}}>click aquí</a>
      </p>

      <FilesScheduled category={'catalogo'}></FilesScheduled>
      
      <Modal
        open={stateModal}
        setState={setStateModal}>
        <LoadCategoryTree  type={inputTypes.UPDATE} send={scheduled} programming></LoadCategoryTree>
      </Modal>
    </div>
  );
}
 