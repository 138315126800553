import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createProductSpecs, scheduledProductSpecs } from './productSpecsAPI';


const initialState = {
  status: 'idle',
  selectedStoreDownload:''
};


/**
 * 
 */
export const createAsync = createAsyncThunk(
  'productSpecs/create',
  async (data) => {
    const response = await createProductSpecs(data.email, data.storeName, data.path);
    return  response
  }
);

export const scheduledAsync = createAsyncThunk(
  'productSpecs/scheduled',
  async (data) => {
    const response = await scheduledProductSpecs(data.email, data.storeName, data.path, data.update, data.date);
    return response
  }
);


export const productSpecsSlice = createSlice({
  name: 'acount',
  initialState,
  reducers: {
    setStore: (state, action) => {
      state.selectedStoreDownload = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createAsync.fulfilled, (state, action) =>  {
        state.status = 'idle';
      })
      .addCase(createAsync.rejected, (state, action)=>{})
  },
});


export const selectedStoreDownload = (state) => state.productSpecs.selectedStoreDownload;

export default productSpecsSlice.reducer;
