import { axiosInstance } from "../../serviceConf";

export function createProductSku( email, storeName, path ) {
  let url = `api/Category/createProductAndSkuFromFile`
  let body = {
    email: email,
    store: storeName,
    path: path
  }
  return new Promise((resolve, reject) =>
    axiosInstance.post(url, body )
    .then((response) => {
      resolve(response.data)
    }).catch((err)=>{
      reject(err)
    })
  )
}

export function downLoadSKU(email, storeName, to ) {
  let url = `api/Category/getAllSkuSpecificationsFromAStore`
  let params = {
    email: email,
    origin: storeName,
    to: to
  }
  return new Promise((resolve, reject) =>
    axiosInstance.get(url,{ params })
    .then((response) => {
      resolve(response.data)
    }).catch((err)=>{
      reject(err)
    })
  )
}

export function createSku(email, storeName, path ) {
  let url = `api/Category/createSkuSpecificationFromFile`
  let body = {
    email: email,
    store: storeName,
    path: path
  }
  return new Promise((resolve, reject) =>
    axiosInstance.post(url, body )
    .then((response) => {
      resolve(response.data)
    }).catch((err)=>{
      reject(err)
    })
  )
}

export function uploadImages(email, storeName ) {
  let url = `ftp/images/uploadMasiveImages`
  let params = {
    email: email,
    store: storeName
  }
  return new Promise((resolve, reject) =>
    axiosInstance.get( url, {params, responseType: 'blob'} )
    .then((response) => {
      resolve(response.data)
    }).catch((err)=>{
      reject(err)
    })
  )
}

export function changeProductSku( email, storeName, path, update ) {
  let url = `api/product/processXlsx`
  let params = {
    email: email,
    store: storeName,
    path: path,
    update: update
  }
  return new Promise((resolve, reject) =>
    axiosInstance.get(url, { params } )
    .then((response) => {
      resolve(response.data)
    }).catch((err)=>{
      reject(err)
    })
  )
}

export function scheduledProductSku( email, storeName, path, update, date ) {
  let dateGmt = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), 0));
  let url = `api/Category/chargeProdructAndSku`
  let body = {
    email: email,
    store: storeName,
    path: path,
    update: update,
    date: dateGmt
  }
  return new Promise((resolve, reject) =>
    axiosInstance.post(url, body )
    .then((response) => {
      resolve(response.data)
    }).catch((err)=>{
      reject(err)
    })
  )
}



export function scheduledSku( email, storeName, path, update, date ) {
  let dateGmt = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), 0));
  let url = `api/Category/chargeSku`
  let body = {
    email: email,
    store: storeName,
    path: path,
    update: update,
    date: dateGmt
  }
  return new Promise((resolve, reject) =>
    axiosInstance.post(url, body )
    .then((response) => {
      resolve(response.data)
    }).catch((err)=>{
      reject(err)
    })
  )
}

export function deleteImages(email, storeName, path ) {
  let url = `api/Sku/deleteAllFilesSku`
  let body = {
    email: email,
    store: storeName,
    path: path
  }

  return new Promise((resolve, reject) =>
    axiosInstance.post( url, body )
    .then((response) => {
      resolve(response.data)
    }).catch((err)=>{
      reject(err)
    })
  )
}