import React, { useState } from 'react';
import { BodyRow } from './BodyRow';
import { HeaderRow } from './HeaderRow';
import { actionTypes } from './TableShema';


export const Table=(props) => {

  const [activeRender, setActiveRender] = useState()

  const setBody = (sizes, position) => {
    let size = 1
    if (sizes && sizes[position]) size = sizes[position]
    return size
  }

  /**
   * crea un arreglo por cada objeto a pintar como data de la tabla
   * @param {object} data resultados de la tabla a pintar
   * @param {Array <object>} headers títulos los de la tabla se usan para determinar el orden de las columnas
   * @param {Array <number>} sizes tamaño de la columna respecto a las otras (flex) 
   * @returns body de la tabla 
   */
  const mapBody=(data, headers, sizes, templates, itemsPerPage, pageSelected) => {
    let initSlice = (pageSelected || 0) * itemsPerPage
    let dataSlice = data
    if(itemsPerPage) dataSlice = data.slice(initSlice, initSlice + itemsPerPage)

    let bodyM = [];
    for (let i = 0; i < dataSlice.length; i++) {
      const row = dataSlice[i];
      bodyM.push([]);
      if(headers.indexOf(hedader=> hedader.key === 'actions' || hedader.key === 'action'))  row.actions = ''
      for (const key in row) {
        let position = orderBodyCell(key,headers)
        if(position != null){
          bodyM[i][position] ={
            key : key,
            value : row[key],
            size : setBody(sizes, position),
            template: findtemplate(key, templates ? templates : []),
            row: row
          }
        }
      }
    }
    return bodyM
  }

  /**
   * evalúa la posición de una celda según los headers para armar el arreglo (body) en dicha posición
   * @param {string} cell id de la celda a evaluar
   * @param {Array <object>} headers listados de títulos en donde buscar
   * @returns index encontrado
   */
  const orderBodyCell = (cell,headers)=>{
    return headers.findIndex((hedader)=>{
      return hedader.key === cell
    })
  }

  /**
   * busca si una celda tiene un template asociado
   * @param {string} cell id de la celda a evaluar
   * @param {Array} templates arreglo de templates para la tabla
   * @returns 
   */
  const findtemplate = (cell, templates) =>{
    return templates.find((template)=>{
      return template.key === cell
    })
  }

  /**
   * crea un arreglo de headers si no se ha pasado uno desde las props
   * @param {*} data primera poción del body de la tabla
   * @returns 
   */
  const populateHeaders = (data) => {
    let headers =[]
    for (const key in data) {
      headers.push({
        key: key
      })
    }
    return headers
  }

  
  let body = []
  if(props.data) {
    body = mapBody(
      props.data, 
      props.headers ? props.headers : populateHeaders(props.data[0]),
      props.sizes,
      props.templates,
      props.itemsPerPage,
      props.pageSelected
    )
  }

  /**
   * determina que hacer en caso de click en una de las filas de la tabla
   * @param {*} row fila seleccionada
   * @param {*} index índice de la fila seleccionada
   */
  const callAction = (row, index) => {
    switch (props.action?.type) {
      case actionTypes.OPEN_ROW:
        if(index === activeRender){
          setActiveRender()
          if (props.action?.retrieveIndex) props.action.retrieveIndex(-1)
        }
        else{
          setActiveRender(index)
          if (props.action?.retrieveIndex) props.action.retrieveIndex(index)
        }
        break;
      case actionTypes.NO_OPEN:
        props.action.func(row)
        break;
      default:
        break;
    }
  }

  return (
    <div className={`table ${props.type} ${props.action && props.action.type !==  actionTypes.NONE ? 'action' : ''}`}>
      {
        props.headers &&
        <HeaderRow headers={props.headers} sizes={props.sizes}></HeaderRow>
      }
      {
        body.map((row, index)=>{
          return (
            <BodyRow key={index} row={row} 
              expandAtion={()=>{callAction(row,index)}} 
              expand={props.action?.func}
              activeExpand={activeRender === index}
            >
            </BodyRow>
          )
        })
      }
    </div>
  );
}