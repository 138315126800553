import React, {useState} from 'react';

import { Box } from "../../ui/box/Box";
import { Title } from '../../ui/title/Title';
import { AddUser } from '../../components/addUser/AddUser';
import { Back } from '../../components/back/Back';
import { Accordion } from '../../ui/accordion/Accordion';
import { Users } from '../../components/users/Users';


export const NewUser=() => {
  const [filterUsers, setFilterUsers] = useState('')

  return (
    <div className="new-user">
      <Back></Back>
      <Title title="Usuarios"></Title>
      <Box>
        <AddUser></AddUser>
      </Box>
      <Accordion
        title = {'Todos los usuarios'}
        search = {filterUsers}
        setSearch = {setFilterUsers}
        searchPlaceholder = "Buscar usuario"
      >
        <Users
          filter = {filterUsers}
        ></Users>
      </Accordion>
    </div>
  );
}
