import React from 'react';
import { useSelector } from 'react-redux';
import { storeActive } from '../../../app/user/userSlice';
import {actions} from'./ConfirmShema'

export const Comfirm=(props) => {
  const store = useSelector(storeActive);

  /**
   * envio de formulario
   */
  const update = (action) => {
    if(props.send) props.send(action)
  }

  /**
   * setea el mensaje del modal
   * @returns string con el mensaje a mostrar 
   */
  const setAction =() =>{
    switch (props.action) {
      case actions.COPY:
        return 'Desea confirmar la replica de la informacion desde'
      case actions.DELETE:
        return 'Desea confirmar la eliminación de la informacion para'
      default:
        return 'Desea confirmar el cargue de la informacion para'
    }
  }
  return (
    <div >
      <div className="form-title">
        <h3>!Verifique la tienda!</h3>
        <p>{setAction()} la tienda <b>{store.store}</b>.</p>
      </div>
      <div className={`form-actions-row`}>
        <button className="link-btn" type='button' onClick={()=>{update(false)}}>
          Cancelar
        </button>
        <button className="secondary-btn"  type='button' onClick={()=>{update(true)}}>
          Confirmar
        </button>
      </div>
    </div>
  );
}
 