import React from 'react';
import { HeaderCell } from './HeaderCell';


export const HeaderRow=(props) => {

  const setSize = (index) => {
    let size = 1
    if (props.sizes && props.sizes[index]) size = props.sizes[index]
    return size
  }

  return (
    <div className="header-row">
      {
        props.headers.map((cell, index)=>{
          return (
            <HeaderCell 
              key={index} 
              cell={cell} 
              size={setSize(index)}
            ></HeaderCell>
          )
        })
      }
    </div>
  );
}