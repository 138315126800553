import React, {useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Box } from "../../ui/box/Box";
import { Title } from '../../ui/title/Title';
import { LoadFile } from '../../components/loadFile/LoadFile';
import { FilesLoaded } from '../../components/filesLoaded/FilesLoaded';
import { Back } from '../../components/back/Back';
import { LoadCategoryTree } from '../../components/loadCategoryTree/LoadCategoryTree';
import { selectUser, storeActive } from '../../../app/user/userSlice';

import { toast } from 'react-toastify';

import {
  createSKUAsync, scheduleSKUdAsync,
  
} from '../../../app/productAndSku/productAndSkuSlice';

import { Modal } from '../../ui/modal/Modal';
import { FilesScheduled } from '../../components/filesScheduled/FilesScheduled';
import { listScheduledAsync } from '../../../app/files/filesSlice';



export const LoadSkuSpecs=() => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const store = useSelector(storeActive);
  const [stateModal, setStateModal] = useState(false)

  const formats =[
    {url:'https://clientes.titamedia.com/master-c/formatos/crear-catalogo/Nuevas-especificaciones-de-sku-masivo.xlsx'}
  ]

  /**
   * envio de archivo
   * @param {*} file archivo a enviar 
   * @returns 
   */
  const send = (file) => {
    return new Promise((resolve, reject) => {
      dispatch(createSKUAsync({
        email:user.email, 
        storeName:store.store, 
        path: file.path
      })).then((response)=>{
        if(response.error){
          toast.error(response.error.message)
        }else{
          resolve()
          toast.success("Especificaciones de SKUS creadas con éxito")
        }
      })
    })
  }

  /**
   * programación del envío del archivo  
   * @param {*} file archivo a enviar
   * @param {*} date fecha de programación 
   * @returns 
   */
  const scheduled = (file, date) => {
    return new Promise((resolve, reject) => {
      dispatch(scheduleSKUdAsync({
        email:user.email, 
        storeName:store.store, 
        path: file.path,
        update: true,
        date: date
      })).then((response)=>{
        if(response.error){
          toast.error(response.error.message)
        }else{
          dispatch(listScheduledAsync({category:'esp producto'}))
          setStateModal(false)
          toast.success("Modificacion de especificaciones de sku programado con éxito")
        }
      })
    })
  }

  return (
    <div className="category-tree">
      <Back></Back>
      <Title title="Cargar especificaciones de SKU"></Title>
      <Box>
        <LoadFile category={'CES_'} type={'uploads'} formats={formats}></LoadFile>
      </Box>
      <FilesLoaded category={'CES_'} type={'uploads'}></FilesLoaded>
      <Box>
        <LoadCategoryTree send={send}></LoadCategoryTree>
      </Box>
      <p> 
        Si deseas programar el cargue de información, haz <a className="link" onClick={()=>{setStateModal(true)}}>click aquí</a>
      </p>
      
      <FilesScheduled category={'esp producto'}></FilesScheduled>

      <Modal
        open={stateModal}
        setState={setStateModal}>
        <LoadCategoryTree send={scheduled} programming></LoadCategoryTree>
      </Modal>

    </div>
  );
}
 