import React from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Back } from '../../components/back/Back'
import { TabsPrices } from '../../components/tabsPrice/TabsPrices'
import { Title } from '../../ui/title/Title'

import { toast } from 'react-toastify';
import { selectUser, storeActive } from '../../../app/user/userSlice';

import {
  downLoadPricesAsync
} from '../../../app/price/priceSlice';

export const LoadPrice = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const store = useSelector(storeActive);

  const formats =[
    [{url:'https://clientes.titamedia.com/master-c/formatos/Precios/Formato-cargue-precios-base.xlsx' }],
    [{url:'https://clientes.titamedia.com/master-c/formatos/Precios/Formato-cargue-precios-programados.xlsx'}]
   
  ]

  /**
   * descaraga de los precios
   * @returns 
   */
  const downLoad =(type) => {
    return new Promise((resolve, reject) => {
      dispatch(downLoadPricesAsync({
        email:user.email, 
        storeName:store.store,
        isFixed: type
      })).then((response)=>{
        if(response.error){
          toast.error(response.error.message)
        }else{
          toast.success("Precios descargados con éxito, la respuesta será enviada al correo electrónico registrado")
        }
      })
    })
  }

  return (
    <div className="category-tree">
        <Back></Back>
        <Title title="Cargue de precios"> </Title>

        <TabsPrices formats={formats} downLoad={downLoad}></TabsPrices>
    </div>
    
  )
}
