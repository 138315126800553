import React from 'react';
import { BodyCell } from './BodyCell';


export const BodyRow=(props) => {

  return (
    <div className={`body-row ${props.activeExpand ? 'expand' : ''}`} onClick={() => {props.expandAtion()}}>
      {
        props.row.map((cell, index)=>{
          return <BodyCell key={index} cell={cell} ></BodyCell>
        })
      }
      {
        props.activeExpand &&
        <div className="more">
          {props.expand(props.row[0].row)}
        </div>
      }
    </div>
  );
}