import React, { useState, useEffect } from 'react';

export const DownLoadCategory=(props) => {
  const [store, setStore] = useState(props.selectDownload ? props.selectDownload.store : '') 
  const [to, setTo] = useState(props.selectDownload ? props.selectDownload.to : '')


  useEffect(() => {
    setStore(props.selectDownload ? props.selectDownload.store : '')
    setTo(props.selectDownload ? props.selectDownload.to : '')
  }, [props.selectDownload]);

  /**
   * validación de llenado de los campos necesarios del formulario
   * @returns booleano con el resultado
   */
  const disabled = () => {
    if( !props.private && (!store || !to) ) return true
    if( props.private && !to ) return true
    return false 
  }

  /**
   * envio del formulario
   */
  const downLoad = () => {
    if(props.private){
      props.send(to).then(()=>{
        setTo('')
      })
    }else{
      props.send(store, to).then(()=>{
        setStore('')
        setTo('')
      })
    }
  }

  if(props.email){
    return (
      <form className="load-category-tree">
        {
          !props.private &&
          <div className="form-row">
            <label>Tienda de orígen:</label>
            <input placeholder="Ingresa la tienda deseada" value={store} onChange={e => setStore(e.target.value)}></input>
          </div>
        }
        <div className="form-row">
          <label>Email:</label>
          <input placeholder="Ingresa tu correo eletrónico" value={to} onChange={e => setTo(e.target.value)}></input>
        </div>
        <div className={`form-actions-row`}>
          <button type="button" className="secondary-btn" disabled={disabled()} onClick={()=>{downLoad()}}>Enviar</button>
        </div>
      </form>
    );
  }

  return (
    <form className="load-category-tree">
      <div className={`form-actions-row`}>
      <button className="secondary-btn" type="button" onClick={()=>{downLoad()}}>Enviar</button>
      </div>  
    </form>
  );
}
 