import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectUser, storeActive } from '../../../app/user/userSlice';

import { Modal } from '../../ui/modal/Modal';
import { modalSizes } from '../../ui/modal/ModalShema';
import { actions } from'../confirm/ConfirmShema'
import { LoadPrices } from '../loadPrices/LoadPrices';

export const ConfirmPrice = (props) => {
  const store = useSelector(storeActive);
  const user = useSelector(selectUser);
  const [stateModal, setStateModal] = useState(false)

    
  const setAction =() =>{
    switch (props.action) {
      case actions.COPY:
        return 'Desea confirmar la replica de la informacion desde'
      default:
        return 'Desea confirmar el cargue de la informacion para'
    }
  }

  return (
    <div >
      <div className="form-title">
        <h3>!Verifique la tienda!</h3>
        <div className="confirm-price-content">
            <p>{setAction()} la tienda <b>{store.store}</b>.</p>
        </div>
        
      </div>
      <div className="confirm-actions-row">
        
        <button className="secondary-btn"  type='button' onClick={()=>{setStateModal(true)}}>
          Si, Confirmar
        </button>
      </div>

      <Modal
            open={stateModal}
            size={modalSizes.STANDAR}
            setState={setStateModal}>
              
            <LoadPrices store={store.store} email={user.email} tab={props.tab} />
            
      </Modal>


    </div>
  )
}
