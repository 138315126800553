import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { inventoryList, inventoryExport, inventoryImport } from '../inventory/inventoryAPI';


const initialState = {
    page:0,
    inventorys:[],
  };

export const inventoryListAsync = createAsyncThunk(
    'inventory/all',
    async ( data, {dispatch} ) => {
      const response = await inventoryList( data.store );
      dispatch(setInventorys(response))
      return  response
    }
);

export const inventoryExportAsync = createAsyncThunk(
    'inventory/export',
    async (data) => {
        const response = await inventoryExport(data.email, data.warehouse, data.store);
        return response
      }
);

export const inventoryImportAsync = createAsyncThunk(
    'inventory/import',
    async (data) => {
        const response = await inventoryImport(data.store, data.email, data.base);
        return response
      }
);


export const inventorySlice = createSlice({
    name: 'inventory',
    initialState,
    reducers: {

        setInventorys: (state , action) =>{
            state.inventorys = action.payload
        }

    },
    extraReducers: (builder) => {
      builder
        .addCase(inventoryListAsync.pending, (state) => {
          state.status = 'loading';
        })
        .addCase(inventoryListAsync.fulfilled, (state, {payload}) =>  {
          state.inventorys = payload
          state.status = 'idle';
        })
        .addCase(inventoryExportAsync.pending, (state)=>{
            state.status = 'loading';
        })
        .addCase(inventoryExportAsync.fulfilled, (state, action) =>  {
            state.status = 'idle';
          })
        .addCase(inventoryImportAsync.pending, (state)=>{
            state.status = 'loading';
        })
        .addCase(inventoryImportAsync.fulfilled, (state, action) =>  {
            state.status = 'idle';
          })
    }

});

export const { setInventorys } = inventorySlice.actions;

export const list = (state) => state.inventory.inventorys;

export default inventorySlice.reducer;