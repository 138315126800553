import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { TextArea } from '../../ui/textArea/TextArea';

import { selectUser } from '../../../app/user/userSlice';

import { toast } from 'react-toastify';

import {
  acountNewAsync,
  selectAcount,
  actionUI,
  empyAcount,
  acountListAsync
} from '../../../app/acount/acountSlice';

export const CreateAccount=( ) => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const count = useSelector(selectAcount);
  const actionActive = useSelector(actionUI);

  const [store, setStore] = useState(count.store)
  const [apiKey, setApiKey] = useState(count.apiKey)
  const [accessToken, setAccessToken] = useState(count.accessToken)

  const disabled = () => {
    if(!store || !apiKey || !accessToken) return true
    return false 
  }

  useEffect(() => {
    setStore(count.store)
    setApiKey(count.apiKey)
    setAccessToken(count.accessToken)
  }, [count]);


  /**
   * guardado / edicion de una cuenta
   */
  const create = () => {
    dispatch(acountNewAsync({
      email:user.email, 
      storeName:store, 
      apiKey, 
      apiToken:accessToken
    })).then((response)=>{
      if(response.error){
        toast.error(response.error.message)
      }else{
        dispatch(acountListAsync())
        dispatch(empyAcount())
        if(actionActive === 'new'){
          setStore('')
          setApiKey('')
          setAccessToken('')
          toast.success("Tienda creada con éxito")
        }else{
          toast.success("Tienda editada con éxito")
        }
      }
    })
  }
  
  return (
    <form className="create-account">
      <div className="form-row">
        <label className="right-aline">Store:</label>
        <input placeholder="Ingresa en nombre de la tienda" 
        value={store} 
        onChange={e => setStore(e.target.value)}
        disabled={actionActive !== 'new'}></input>
      </div>
      <div className="form-row">
        <label className="right-aline">ApiKey:</label>
        <input placeholder="Ingresa el apikey" value={apiKey} onChange={e => setApiKey(e.target.value)}></input>
      </div>
      <div className="form-row">
        <label className="right-aline">Access token:</label>
        <TextArea placeholder="Ingresa el accesstoken" value={accessToken} onChange={e => setAccessToken(e.target.value)}></TextArea>
      </div>
      <div className="form-actions-row">
      {
          actionActive !== 'new' &&
          <button className="primary-btn" type="button" onClick={()=>{dispatch(empyAcount())}}>
            Cancelar
          </button>
        }
        <button type="button" className="secondary-btn" disabled={disabled()} onClick={()=>{create()}}>
          {actionActive === 'new'? 'Crear':'Editar'}
        </button>
      </div>
    </form>
  );
}
 