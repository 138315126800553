import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Box } from "../../ui/box/Box";

import { Title } from '../../ui/title/Title';
import { LoadAndSendFile } from '../../components/LoadAndSendFile/LoadAndSendFile';
import { Back } from '../../components/back/Back';
import { selectUser, storeActive } from '../../../app/user/userSlice';

import { actions } from'../../components/confirm/ConfirmShema'

import { toast } from 'react-toastify';

import {
  deletePricesAsync
} from '../../../app/price/priceSlice';


export const DeletePrices=() => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const store = useSelector(storeActive);

  const formats =[
    {url:'https:///clientes.titamedia.com/master-c/formatos/Precios/formato-eliminar-precios-sku.xlsx'}
  ]
  

  /**
   * envio de archivo
   * @param {*} file archivo a enviar
   * @returns 
   */
  const send = async (file) => {
    return new Promise((resolve, reject) => {
      dispatch(deletePricesAsync({
        email:user.email, 
        storeName:store.store, 
        file: file
      })).then((response)=>{
        if(response.error){
          toast.error(response.error.message)
        }else{
          resolve()
          toast.success("Precios eliminados con éxito")
        }
      })
    })
  }

  return (
    <div className="category-tree">
      <Back></Back>
      <Title title="Eliminar Precios de SKU"></Title>
      <Box>
        <LoadAndSendFile type={'uploads'} send={send} formats={formats} action={actions.DELETE}></LoadAndSendFile>
      </Box>
    </div>
  );
}
 