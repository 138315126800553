import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RadioOrCheck } from '../../ui/radioOrCheck/RadioOrCheck';
import { inputTypes } from '../../ui/radioOrCheck/RadioOrCheckShema';
import { Select } from '../../ui/select/Select';
import {inputTypes as inputTypesRow} from './LoadCategoryTreeShema';

import { list } from '../../../app/files/filesSlice';

import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import es from 'date-fns/locale/es';

import { Modal } from "../../ui/modal/Modal";
import { modalSizes } from '../../ui/modal/ModalShema';
import { Comfirm } from '../../components/confirm/Confirm';

export const LoadCategoryTree=(props) => {
  const filesList = useSelector(list);
  
  const [file, setFile] = useState('') 
  const [boolean, setBoolean] = useState('') 

  const action = (value) =>{
    setFile(value);
  }

  const [startDate, setStartDate] = useState();
  const [startTime, setStartTime] = useState();

  const [stateModalConfirm, setStateModalConfirm] = useState(false)

  /**
   * validación de llenado de los campos necesarios del formulario
   * @returns booleano con el resultado
   */
  const disabled = () => {
    let programming = true
    if(props.programming && (!startDate || !startTime)){
      programming = false
    }
   
    switch (props.type) {
      case inputTypesRow.NEW:
      case inputTypesRow.UPDATE:
        if(file && boolean && programming) return false
        break;
      default:
        if(file && programming) return false
        break;
    }
    return true
  }

  /**
   * envio de formulario
   */
  const update = (confirm) => {
    let date = ''
    setStateModalConfirm(false)
    if(!confirm) return
    if(startTime && startDate) {
      date = startDate
      date.setHours(startTime.getHours(), startTime.getMinutes())
    }
    switch (props.type) {
      case inputTypesRow.NEW:
      case inputTypesRow.UPDATE:
        props.send(file, boolean === 'SI' ? true : false, date).then(()=>{
          setFile('')
          setBoolean()
          setStartDate()
          setStartTime()
        })
        break;
      default:
        props.send(file, date).then(()=>{
          setFile('')
          setStartDate()
          setStartTime()
        })
        break;
    }
  }

  /**
   * genera el template que contiene la última fila del formulario y la acción de enviar
   * @param {*} label titulo del campo
   * @param {*} name nombre del grupo del campo
   * @param {*} style clase css adicional para la fila
   * @returns html con la última fila del formulario
   */
  const setTemplate = (label, name, style) =>{
    let action = (
      <button className="secondary-btn" disabled={disabled()} type='button' onClick={()=>{setStateModalConfirm(true)}}>
        {props.programming ? 'Programar' : 'Enviar'}
      </button>
    )
    if(label){
      return (
        <div className={`form-actions-row actions--inline ${style}`}>
          <label>{label}:</label>
          <div className="input-and-action">
            <div className="radios">
              <RadioOrCheck label="SI" type={inputTypes.RADIO} name={name} select={setBoolean} ></RadioOrCheck>
              <RadioOrCheck label="NO" type={inputTypes.RADIO} name={name} select={setBoolean}></RadioOrCheck>
            </div>
            {action}
          </div>
        </div>
      )
    }
    return (
      <div className={`form-actions-row`}>
        {action}
      </div>
    )
  }

  /**
   * determina el tipo de formulario que se debe pintar
   * @param {*} type tipo de formulario enviado por props
   * @returns función que pinta la última fila del formulario
   */
  const setActionRow = (type) =>{
    switch (type) {
      case inputTypesRow.NEW:
        return setTemplate('Es un formato de SKU', 'new', 'more-label' )
      case inputTypesRow.UPDATE:
        return setTemplate('Actualizar', 'update' )
      default:
        return setTemplate()
    }
  }

  useEffect(() => {
    registerLocale('es', es)
    setDefaultLocale('es');
  }, []);

  /**
   * valida si la hora aun no a pasado, teniendo encuenta el dia 
   * @param {*} time hora seleccionada
   * @returns 
   */
  const filterPassedTime = (time) => {
    const currentDate = new Date();
    let selectedDate = new Date(time);
    if(startDate) selectedDate = startDate

    selectedDate.setHours(time.getHours(), time.getMinutes())

    return currentDate < selectedDate;
  };

  /**
   * si se pasa la validacion (filterPassedTime()) se setea la hora 
   * @param {*} time hora seleccionada
   */
  const setFilterStartTime = (time) => {
    if(filterPassedTime(time)) setStartTime(time)
  }

  /**
   * si se cambia la fecha se valida nuevamente la hora y si no es valida se setea una hora valida 
   */
  useEffect(() => {
    let validTime = new Date()
    validTime.setHours(new Date().getHours()+1, 0)
    if(startTime) {
      let currentTime = new Date()
      currentTime.setHours(startTime.getHours(), startTime.getMinutes())
      if(!filterPassedTime(currentTime)) setStartTime(validTime)
    }
  }, [startDate]);

  return (
    <form className="load-category-tree">
      {
        props.programming &&
        <div className="form-title">
          <h3>Programar cargue de información</h3>
          <p>Ingresa la siguiente información para realizar la programación.</p>
        </div>
      }
      <div className="form-row">
        <label>Ruta del archivo: </label>
        <Select 
          options={filesList}
          placeholder="seleccione una opción"
          selected={action}
          selectValue={file.name}
        ></Select>
        
      </div>
      {
        props.programming &&
        <div className="form-actions-row actions--inline">
          <label>Fecha del cargue:</label>
          <div className="input-and-action ">
            <DatePicker 
              selected={startDate} 
              onChange={(date) => setStartDate(date)}
              portalId="root-portal2"
              dateFormat="dd/MM/yyyy"
              placeholderText="dd/mm/aaaa"
              excludeDateIntervals={[{start: new Date(1995, 11, 17), end: new Date().setDate(new Date().getDate()-1) }]}
            />
            <label>Hora:</label>
            <DatePicker 
              selected={startTime} 
              onChange={(date) => setFilterStartTime(date)}
              showTimeSelectOnly
              showTimeSelect
              timeCaption="Time"
              dateFormat="h:mm aa"
              timeIntervals={30}
              portalId="root-portal"
              placeholderText="h:mm aa"
              filterTime={filterPassedTime}
            />
          </div>
        </div>
      }
      {setActionRow(props.type)}
      
     
      <Modal
        open={stateModalConfirm}
        setState={setStateModalConfirm}
        size={modalSizes.EXTRA_SHORT}>
        <Comfirm send={update} action={props.action}></Comfirm>
      </Modal>
    </form>
  );
}
 