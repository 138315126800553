import React, { useState, useEffect } from 'react';
import { axiosInstance, axiosInstance2 } from "../../../serviceConf";

export const Loader=(props) => {
  const [callPending, setCallPending] = useState(0)

  useEffect(()=>{
    axiosInstance.interceptors.request.use((config) => {
      setCallPending(callPending + 1);
      return config;
    }, (error) => {
        return Promise.reject(error);
    });
    axiosInstance.interceptors.response.use((response) => {
      setCallPending(callPending - 1);
      return response;
    }, (error) => {
      setCallPending(callPending - 1);
      return Promise.reject(error);
    });

    axiosInstance2.interceptors.request.use((config) => {
      setCallPending(callPending + 1);
      return config;
    }, (error) => {
        return Promise.reject(error);
    });
    axiosInstance2.interceptors.response.use((response) => {
      setCallPending(callPending - 1);
      return response;
    }, (error) => {
      setCallPending(callPending - 1);
      return Promise.reject(error);
    });
  },[])


  return (
    <>{
      callPending > 0 &&
      <div className='loader'>
        <div className="spinner-box">
          <div className="circle-border">
            <div className="circle-core"></div>
          </div>
        </div>
        <h3>Estamos procesando tu solicitud</h3>
      </div>
    }
    </>
  );
}