import { axiosInstance } from "../../serviceConf";

export function downLoadFile( file ) {
  let url = `api/upload/files/${file}`

  return new Promise((resolve, reject) =>
    axiosInstance.get(url,{ responseType: 'blob'})
    .then((response) => {
      resolve(response.data)
    }).catch((err)=>{
      reject(err)
    })
  )
}

export function deleteFile( file ) {
  let url = `api/upload/delete/${file}` 

  return new Promise((resolve, reject) =>
    axiosInstance.get(url,{ })
    .then((response) => {
      resolve(response.data)
    }).catch((err)=>{
      reject(err)
    })
  )
}

export function listFiles( directory ) {
  let url = `api/upload/files` 
  let params = {
    directory: directory
  }
  return new Promise((resolve, reject) =>
    axiosInstance.get(url,{ params })
    .then((response) => {
      resolve(response.data)
    }).catch((err)=>{
      reject(err)
    })
  )
}

export function loadFile( file, type ) {
  let url = `api/upload/uploadByType`
  let params = {
    type: type
  }
  let config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }

  const formData = new FormData();
  formData.append('file',file[0])

  return new Promise((resolve, reject) =>
    axiosInstance.post(url,formData,{params}, config )
    .then((response) => {
      resolve(response.data)
    }).catch((err)=>{
      reject(err)
    })
  )
}

export function listFilesScheduled() {
  let url = `api/Category/charge/list` 
  return new Promise((resolve, reject) =>
    axiosInstance.get(url,{ })
    .then((response) => {
      resolve(response.data)
    }).catch((err)=>{
      reject(err)
    })
  )
}

export function deleteFileScheduled( jobName, jobGroup ) {
  let url = `api/Category/deletecharge/${jobName}/${jobGroup}` 

  return new Promise((resolve, reject) =>
    axiosInstance.get(url,{ })
    .then((response) => {
      resolve(response.data)
    }).catch((err)=>{
      reject(err)
    })
  )
}