import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Box } from "../../ui/box/Box";

import { Title } from '../../ui/title/Title';
import { Back } from '../../components/back/Back';
import { DownLoadCategory } from '../../components/downLoadCategory/DownLoadCategory';
import { selectUser, storeActive } from '../../../app/user/userSlice';
import { FilesLoaded } from '../../components/filesLoaded/FilesLoaded';

import { toast } from 'react-toastify';

import {
  downLoadAttrAsync,
  selectDownload
} from '../../../app/productAttr/productAttrSlice';


export const DownLoadProductAttr=() => {
  const selectDownloadCategory = useSelector(selectDownload);
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const store = useSelector(storeActive);

  /**
   * descarga de archivo
   * @param {*} to email donde se enviara el archivo
   * @returns 
   */
  const downLoad = (to) => {
    return new Promise((resolve, reject) => {
      dispatch(downLoadAttrAsync({
        email:user.email, 
        storeName:store.store, 
        to
      })).then((response)=>{
        if(response.error){
          toast.error(response.error.message)
        }else{
          toast.success("Atributos de producto descargados con éxito, la respuesta será enviada al correo electrónico proporcionado")
          resolve()
        }
      })
    })
  }

  return (
    <div className="category-tree">
      <Back></Back>
      <Title title="Descargar atributos de producto"></Title>
      <Box>
        <DownLoadCategory email private selectDownload={selectDownloadCategory} send={downLoad}></DownLoadCategory>
      </Box>
      <FilesLoaded type={'files'}></FilesLoaded>
    </div>
  );
}
 