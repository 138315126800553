
import { axiosInstance2 } from "../../serviceConf";




export function inventoryList (storeName) {
    let url = `api/inventory`
    let params = {
      store: storeName
    }

    return  new Promise((resolve, reject) =>
      axiosInstance2.get(url,{ params })
      .then((response) => {
        resolve(response.data)
      }).catch((err)=>{
        reject(err)
      })
    )
  }
  

  export function inventoryExport ( email, id, storeName ) {
    let url = `/api/inventory/export`
    let params = {
      email: email,
      warehouse: id,
      store: storeName
      
    }

    return new Promise((resolve, reject) =>
      axiosInstance2.get(url,{ params })
      .then((response) => {
        resolve(response.data)
      }).catch((err)=>{
        reject(err)
      })
    )
  }


  
  export function inventoryImport ( storeName, email, base  ) {
    let url = `/api/inventory/import`
    let body = {
      store: storeName,
      email: email,
      base: base
      
    }

    return new Promise((resolve, reject) =>
      axiosInstance2.post(url, body )
      .then((response) => {
        resolve(response.data)
      }).catch((err)=>{
        reject(err)
      })
    )
  }