import React, { useState, useEffect } from 'react';
import { SelectItem } from './SelectItem';


export const Select=(props) => {

  const [active, setState] = useState(false)
  const [selectValue, setSelected] = useState(props.selectValue)

  const [filter, setFilter] = useState('')
  const [options, setOptions] = useState()
  
  /**
   * selecciona una opción del selector
   * @param {*} option opción seleccionada
   */
  const selectedItem = (option) =>{
    setSelected(option.label)
    setState(!active)
    props.selected(option)
  }

  /**
   * muestra u oculta la lista de opciones
   * @param {*} event evento click
   */
  const toggleState = (event) => {
    event.stopPropagation();
    setState(!active)
    window.addEventListener( 'click', () =>{
      setState(false)
    }, { once: true })
  }

  useEffect(() => {
    setSelected(props.selectValue)
  },[props.selectValue])

  /**
   * filtra las opciones 
   */
  useEffect(() => {
    let list =[]
    list = props.options?.map((option, index) =>{
      if ((filter && new RegExp(filter, 'i').test(option.label)) || !filter) {
        return {
          ...option
        }
      }
    }).filter(Boolean)
    setOptions(list)
  },[props.options, filter])

  return (
    <div className="select">
      <div className={`select-input ${selectValue ? 'selected' : ''}`} onClick={(e)=>{toggleState(e)}}>
        <div className={`input-box ${!selectValue ? 'placeholder' : ''}`}>
          {
            selectValue ?
            selectValue :
            props.placeholder
          }
        </div>
        <i className={`icon icon-s icon-action icon-arrow-${!active ? 'down': 'up'}`}></i>
      </div>
      {
        active &&
        <ul className="select-list">
          {
            props.filter &&
            <li className='search-input' onClick={(e)=>{e.stopPropagation()}}>
              <i className="icon icon-xs icon-search"></i>
              <input className='border' placeholder="Buscar" value={filter} onChange={e => setFilter(e.target.value)}/>
            </li>
          }
          {
            options?.map((option, index) => {
              return (
                <SelectItem 
                  {...option} 
                  key={index} 
                  active={selectValue === option.label}
                  action={()=>{selectedItem(option)}}
                ></SelectItem>
              )
            })
          }
          {
            options.length === 0 &&
            <li className='emty' onClick={(e)=>{e.stopPropagation()}}>
              Sin resultados
            </li>
          }
        </ul>
      }  
    </div>
  );
}