export const tableTypes = {
  LOWER_BORDER:"lower-border",
  ALL_BORDER:"all-border",
  NO_BORDER:"no-border",
  CARD:"card-type"
}

export const actionTypes = {
  OPEN_ROW:"OPEN_ROW",
  NO_OPEN:"NO_OPEN",
  NONE:"NONE"
}

export const shema = {
  headers:[
    {
      name:"",
      key:""
    }
  ],
  body:[
    {}
  ],
  templates:[
    {
      key:"",
      render:""
    }
  ],
  sizes:[
    1
  ],
  type: tableTypes.NO_BORDER,
  action:{
    func:"",
    retrieveIndex:"",
    type: actionTypes.OPEN_ROW
  }
}