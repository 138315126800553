import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { priceFixedImport, priceImport, deletePrices, downLoadPrices } from '../price/priceAPI';


const initialState = {
    status: 'idle'
};


export const priceFixedImportAsync = createAsyncThunk(
    'price/fixed/import',
    async (data) => {
        const response = await priceFixedImport(data.store, data.email, data.base);
        return response
      }
);

export const priceImportAsync = createAsyncThunk(
    'price/import',
    async (data) => {
        const response = await priceImport(data.store, data.email, data.base);
        return response
      }
);

export const deletePricesAsync = createAsyncThunk(
  'price/delete',
  async (data) => {
    const response = await deletePrices(data.email, data.storeName, data.file);
    return response
  }
);

export const downLoadPricesAsync = createAsyncThunk(
  'price/downLoad',
  async (data) => {
    const response = await downLoadPrices(data.email, data.storeName, data.isFixed);
    return response
  }
);


export const priceSlice = createSlice({
    name: 'price',
    initialState,
    reducers: {

        setPrices: (state , action) =>{
            state.prices = action.payload
        }

    },
    extraReducers: (builder) => {
      builder
        .addCase(priceFixedImportAsync.pending, (state) => {
          state.status = 'loading';
        })
        .addCase(priceFixedImportAsync.fulfilled, (state, {payload}) =>  {
          state.inventorys = payload
          state.status = 'idle';
        })
        .addCase(priceImportAsync.pending, (state)=>{
            state.status = 'loading';
        })
        .addCase(priceImportAsync.fulfilled, (state, action) =>  {
            state.status = 'idle';
        })
        .addCase(deletePricesAsync.pending, (state) => {
          state.status = 'loading';
        })
        .addCase(deletePricesAsync.fulfilled, (state, action) =>  {
          state.status = 'idle';
        })
        .addCase(deletePricesAsync.rejected, (state, action)=>{})
        .addCase(downLoadPricesAsync.pending, (state) => {
          state.status = 'loading';
        })
        .addCase(downLoadPricesAsync.fulfilled, (state, action) =>  {
          state.status = 'idle';
        })
        .addCase(downLoadPricesAsync.rejected, (state, action)=>{})
    }

});


export const { setPrices } = priceSlice.actions;

export default priceSlice.reducer;