import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Box } from "../../ui/box/Box";

import { Title } from '../../ui/title/Title';
import { LoadFile } from '../../components/loadFile/LoadFile';
import { Back } from '../../components/back/Back';
import { selectUser, storeActive } from '../../../app/user/userSlice';
import { FilesLoaded } from '../../components/filesLoaded/FilesLoaded';
import { LoadCategoryTree } from '../../components/loadCategoryTree/LoadCategoryTree';
import { actions } from'../../components/confirm/ConfirmShema'

import { toast } from 'react-toastify';

import {
  deleteImagesAsync
} from '../../../app/productAndSku/productAndSkuSlice';


export const DeleteImg=() => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const store = useSelector(storeActive);

  const formats =[
    {url:'https://clientes.titamedia.com/master-c/formatos/Modificar/eliminar-imagenes-sku.xlsx'}
  ]
  
  /**
   * envio de archivo
   * @param {*} file archivo a enviar
   * @returns 
   */
  const send = (file) => {
    return new Promise((resolve, reject) => {
      dispatch(deleteImagesAsync({
        email:user.email, 
        storeName:store.store, 
        path: file.path,
      })).then((response)=>{
        if(response.error){
          toast.error(response.error.message)
        }else{
          resolve()
          toast.success("Imágenes eliminadas con éxito")
        }
      })
    })
  }

  return (
    <div className="category-tree">
      <Back></Back>
      <Title title="Eliminar Imágenes de SKU"></Title>
      <Box>
        <LoadFile category={'DIS_'} type={'uploads'} formats={formats}></LoadFile>
      </Box>
      <FilesLoaded type={'uploads'} category={'DIS_'}></FilesLoaded>
      <Box>
        <LoadCategoryTree send={send} action={actions.DELETE}></LoadCategoryTree>
      </Box>
    </div>
  );
}
 