import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { priceFixedImportAsync, priceImportAsync } from '../../../app/price/priceSlice';

import { toast } from 'react-toastify';

export const LoadPrices = ( {email, store, tab} ) => {

            const [fileSelectedPrevious, setFileSelectedPrevious] = useState(null);
            const [file, setFile] = useState('') 
            const dispatch = useDispatch();

        

            /**
             * validación de llenado de los campos necesarios del formulario
             * @returns booleano con el resultado
             */
            const disabled = () => {
            if(file) return false
            return true
            }

            const changeFile = (e) => {
                setFile(e.target.files[0]);
                if (e.target.files[0] !== undefined) {
                const reader = new FileReader();
                reader.readAsDataURL(e.target.files[0]);
                reader.onload = (e) => {
                    e.preventDefault();
                    setFileSelectedPrevious(e.target.result.split(',')[1]); // le damos el base64 del file 
                };
            }
        };

        
        const importPriceFixed = () => {
            return new Promise((resolve, reject) => {
            dispatch(priceFixedImportAsync({
            
                store:store,
                email:email, 
                base: fileSelectedPrevious
            })).then((response)=>{
                if(response.error){
                toast.error(response.error.message)
                }else{
                toast.success("Precios Importados con éxito ")
                resolve()
                setFile(false)
                }
            })
            })
        }

        const importPrice = () => {
            return new Promise((resolve, reject) => {
            dispatch(priceImportAsync({
            
                store:store,
                email:email, 
                base: fileSelectedPrevious
            })).then((response)=>{
                if(response.error){
                toast.error(response.error.message)
                }else{
                toast.success("Precios Importados con éxito ")
                resolve()
                setFile(false)
                }
            })
            })
        }

      

  return (
    <>
            <h3>Importa tus archivos: </h3>
            <div className="drag-container">
                {file 
                    ?<div className="drag-upload-file-ctn">
                        <div className="drag-upload-file">
                            <p>{file.name}</p>
                            <i className="icon icon-delete-color icon-action" onClick={() => {setFile(false)}}></i>
                        </div>
                    </div>
                    :
                
                <div className="drag-upload-wrap">
                    <div className="icon icon-drag">
                        <div className="text-information">
                            <input
                                className="file-upload-input"
                                type="file"
                                accept="files/*"
                                multiple
                                onChange={(e) => {
                                    changeFile(e);
                                }}
                            />
                        <a className="link" >Elige el archivo</a> desde el ordenador o puedes soltarlo aqui
                        </div>
                </div>
                </div>
                }
            
            </div>
            {
              tab===1
              ? <div className="drag-btn-container">
                    <button className="secondary-btn" disabled={disabled()} type='button' onClick={importPriceFixed}>Importar</button>
                </div>
            
              : <div className="drag-btn-container">
                    <button className="secondary-btn" disabled={disabled()} type='button' onClick={importPrice}>Importar</button>
                </div>

            }
            
    </>
  )
}
