export const modalSizes = {
  MEDIUM:"size-m",
  LARGE:"size-l",
  SHORT:"size-s",
  EXTRA_LARGE:"size-xl",
  EXTRA_SHORT:"size-xs",
  STANDAR: "size-standar"
}

export const modalFooter = {
  cancel:{
    text:"",
    action:"",
    disabled:false
  },
  send:{
    text:"",
    action:"",
    disabled:false
  }
}
export const modalSearch = {
  set:"",
  value:""
}