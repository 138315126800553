import React from 'react';


export const SelectItem=(props) => {

  /**
   * valida si esta avilitada la opcion y si es asi la cambia
   * @returns 
   */
  const selected = () => {
    if(props.disabled) return
    props.action()
  }

  return (
    <li className={`${props.active ? 'active' : ''} ${props.disabled ? 'disabled' : ''}`} onClick={()=>{selected()}}>
      {props.label}
    </li>
  );
}