import React, { useState } from 'react';


export const RadioOrCheck=(props) => {

  const [id, setId] = useState(Math.random())

  /**
   * setea el valor 
   */
  const select = () => {
    props.select(props.label)
  }

  return (
    <div className="radio-or-check" onClick={()=>{select()}}>
      <label htmlFor={`roc-${id}`}>{props.label}</label>
      <input type={props.type} id={`roc-${id}`} name={props.name}></input>
      <label htmlFor={`roc-${id}`} className='label-mask'></label>
    </div>
  );
}