import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Box } from "../../ui/box/Box";
import { Title } from '../../ui/title/Title';
import { LoadFile } from '../../components/loadFile/LoadFile';
import { FilesLoaded } from '../../components/filesLoaded/FilesLoaded';
import { Back } from '../../components/back/Back';
import { LoadCategoryTree } from '../../components/loadCategoryTree/LoadCategoryTree';
import { inputTypes } from '../../components/loadCategoryTree/LoadCategoryTreeShema';
import { selectUser, storeActive } from '../../../app/user/userSlice';

import { toast } from 'react-toastify';

import {
changeAsync,
} from '../../../app/productAndSku/productAndSkuSlice';


export const ChangeProductAndSku=() => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const store = useSelector(storeActive);

  const formats =[
    {
      url:'https://clientes.titamedia.com/master-c/formatos/Modificar/modificar-valores-producto.xlsx',
      type: 'producto'
    },
    {
      url:'https://clientes.titamedia.com/master-c/formatos/Modificar/modificar-valores-sku.xlsx',
      type: 'Sku'
    }
  ]

  /**
   * envio de archivo
   * @param {*} file archivo a enviar
   * @param {Boolean} sku 
   * @returns 
   */
  const send = (file, sku) => {
    return new Promise((resolve, reject) => {
      dispatch(changeAsync({
        email:user.email, 
        storeName:store.store, 
        update:!sku,
        path: file.path
      })).then((response)=>{
        if(response.error){
          toast.error(response.error.message)
        }else{
          resolve()
          toast.success("Productos y SKU'S cargados con éxito")
        }
      })
    })
  }



  return (
    <div className="category-tree">
      <Back></Back>
      <Title title="Modificar valores de productos y skus"></Title>
      <Box>
        <LoadFile category={'MPS_'} type={'uploads'} formats={formats}></LoadFile>
      </Box>
      <FilesLoaded  type={'uploads'} category={'MPS_'}></FilesLoaded>
      <Box>
        <LoadCategoryTree type={inputTypes.NEW} send={send}></LoadCategoryTree>
      </Box>
 
    </div>
  );

}
 