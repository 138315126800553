import React from 'react';


export const Title=(props) => {

  return (
    <div className="page-title">
      <h3>
        {props.title}
      </h3>
  </div>
  );
}