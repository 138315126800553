import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Box } from "../../ui/box/Box";

import { Title } from '../../ui/title/Title';
import { Back } from '../../components/back/Back';
import { DownLoadCategory } from '../../components/downLoadCategory/DownLoadCategory';
import { selectUser, storeActive } from '../../../app/user/userSlice';

import { toast } from 'react-toastify';

import { 
  downLoadAsync 
} from '../../../app/productAttr/productAttrSlice';

export const DownLoadAttrAndSku=() => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const store = useSelector(storeActive);

  /**
   * descarga de archivo
   * @returns 
   */
  const downLoad = () => {
    return new Promise((resolve, reject) => {
      dispatch(downLoadAsync({
        email:user.email, 
        storeName:store.store
      })).then((response)=>{
        if(response.error){
          toast.error(response.error.message)
        }else{
          toast.success("Atributos de producto y SKU descargado con éxito")
          resolve()
        }
      })
    })
  }

  return (
    <div className="category-tree">
      <Back></Back>
      <Title title="Descargar atributos de producto y SKU (Árbol de categorías) "></Title>
      <Box>
        <DownLoadCategory send={downLoad}></DownLoadCategory>
      </Box>
    </div>
  );
}
 