import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createProductBrands, downloadProductBrands, replicateBrands } from './productBrandAPI';


const initialState = {
  status: 'idle',
  selectedStoreDownload:''
};


/**
 * 
 */
export const createAsync = createAsyncThunk(
  'productBrand/create',
  async (data) => {
    const response = await createProductBrands(data.email, data.storeName, data.path);
    return  response
  }
);

export const downLoadAsync = createAsyncThunk(
  'productBrand/download',
  async (data) => {
    const response = await downloadProductBrands(data.origin, data.email);
    const url = window.URL
        .createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `marcas.xlsx`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    return 'OK'
  }
);

export const replicateAsync = createAsyncThunk(
  'productBrand/replicate',
  async (data) => {
    const response = await replicateBrands(data.email, data.origin, data.destiny);
    return  response
  }
);

export const productBrandSlice = createSlice({
  name: 'acount',
  initialState,
  reducers: {
    setStore: (state, action) => {
      state.selectedStoreDownload = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createAsync.fulfilled, (state, action) =>  {
        state.status = 'idle';
      })
      .addCase(createAsync.rejected, (state, action)=>{})
      .addCase(replicateAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(replicateAsync.fulfilled, (state, action) =>  {
        state.status = 'idle';
      })
      .addCase(replicateAsync.rejected, (state, action)=>{})
  },
});


export const selectedStoreDownload = (state) => state.productBrand.selectedStoreDownload;

export default productBrandSlice.reducer;
