import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Table } from '../../ui/table/Table';
import { tableTypes } from '../../ui/table/TableShema';

import { toast } from 'react-toastify';

import {
  downLoadAsync,
  deleteAsync,
  listAsync,
  list
} from '../../../app/files/filesSlice';

export const FilesLoaded=(props) => {

  const dispatch = useDispatch();
  const filesList = useSelector(list);

  useEffect(() => {
    dispatch(listAsync({directory:props.type, category: props.category}))
  }, []);

  /**
   * se realiza copia de la data para poderla modificar
   * @returns copia de la data
   */
  const populateData = () =>{
    return filesList.map((file) => {
      return {
        ...file
      }
    })
  }

  /**
   * elimina una archivo
   * @param {Object} data fila de la tabla que contiene el archivo a eliminar
   */
  const deleteFile = (data) => {
    dispatch(deleteAsync({
      file:data.name
    })).then((response)=>{
      if(response.error){
        toast.error(response.error.message)
      }else{
        dispatch(listAsync ({directory:props.type, category:props.category}))
        toast.success("Archivo eliminado con éxito")
      }
    })
  }

  /**
   * descarga una archivo
   * @param {Object} data fila de la tabla que contiene el archivo a descargar
   */
  const dowloadFile = (data) => {
    dispatch(downLoadAsync({
      file:data.name
    }))
  }

  /**
   * modifica la columna acciones de la tabla
   * @param {Object} data fila de la tabla
   * @returns celda modificada
   */
  const templateActions = (data) =>{
    return (
      <div className='table-actions'>
        <i className="icon icon-import-color icon-action" onClick={() => {dowloadFile(data)}}></i>
        <i className="icon icon-delete-color icon-action" onClick={() => {deleteFile(data)}}></i>
      </div>
    )
  }

  /**
   * modifica la columna archivo de la tabla
   * @param {Object} data fila de la tabla
   * @returns celda modificada
   */
  const templateFile = (data) =>{
    return (
      <a className="file large">
        {data.name}
      </a>
    )
  }

  /**
   * modifica la columna path de la tabla
   * @param {Object} data fila de la tabla
   * @returns celda modificada
   */
   const templatePath = (data) =>{
    return (
      <p className="large">
        {data.path}
      </p>
    )
  }

  const headers =[
    {name:'Archivo:', key:'name',},
    {name:'Path:', key:'path'},
    {name:'', key:'actions'}
  ]

  const templates = [
    {
      key: 'actions',
      render: templateActions
    },
    {
      key: 'name',
      render: templateFile
    },
    {
      key: 'path',
      render: templatePath
    }
  ]

  return (
    <div className="files-loaded">
      <Table
        data={populateData()} 
        headers={headers} 
        templates={templates}
        sizes = {[4,5,1.4]}
        type={tableTypes.ALL_BORDER}
      >
      </Table>
    </div>
  );
}
 