import React, { useState } from 'react'
import { Dropdown } from '../dropdown/Dropdown'

export const Card = (props) => {

    const [id, setId] = useState('')

    const handleClick = (e) =>{
      let index = e.currentTarget.id
      setId(index);
    }


    return (
   <div className="card-container">
      
        <div className="card-subtitle">
             <h3>{props.almacen}</h3>
             <div onClick={handleClick} id={props.id}>
               <Dropdown  opt1={'Exportar archivo'} opt2={'Importar archivo'} id={id} />
             </div>
          </div>


        <div className="card-label">
          <div>
            <label className="card-label-1">{props.label}</label>
            <label>{props.id}</label>
          </div>
          
          <div>
              {
                props.estado
                ?<label className="card-button-active">{'Activa'}</label>
                :<label className="card-button-inactive">{'Inactiva'}</label>
              }
          </div>
         
        </div>
   </div>
  )
   
}
